import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Input, Button, Label } from 'reactstrap'
import GetFileListBySubCategoryService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListBySubCategoryService';
import { connect } from 'react-redux';
import CustomModal from '../../../../../../../../../../Components/Modals/CustomModal';
import Rename from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Rename.svg'
import UploadButton_Dark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/UploadButton_Dark.svg'
import Comment_Dark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Comment_Dark.svg'
import TickMark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/TickMark.svg'
import Download from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Download.svg'
import Close from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Close.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faAngleDown, faAngleUp, faCloudUploadAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import RenameFileAssetService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/RenameFileAssetService';
import DeleteFileAssetService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/DeleteFileAssetService';
// import UploadAssetGalleryMainPage from './UploadAssetGalleryMainPage';
import GetAllProblemAreaPathwaysService from '../../../../../../../../../../../Service/DashboardServices/HeplDeskServices/GetAllProblemAreaPathwaysService';
import GetFileListByCategoryService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListByCategoryService';
import getAllOriginalFilesByAssetIdentifierService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/getAllOriginalFilesByAssetIdentifierService';
import uploadAssetsService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/uploadAssetsService';
import linkImageToGAFileService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/linkImageToGAFileService';
import Send from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Send.svg'
import User from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/User.svg'
import addAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/addAssetNoteService';
import getAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/getAssetNoteService';
import RenameFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/RenameFileAssetNewService';
import DeleteFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/DeleteFileAssetNewService';
function VideosForBrand(props) {
    const [imageUrl, setImageUrl] = useState(false)
    const [isOpengalleryPopUp, setIsOpengalleryPopUp] = useState(false)
    const [isAssetPopUp, setsAssetPopUp] = useState(false)
    const [brandProfile, setBrandProfile] = useState();
    const [brandId, setBrandId] = useState();
    const [assetPageRender, setAssetPageRender] = useState(false);
    const [isSharedByYou, setIsSharedByYou] = useState(true)
    // const [fileListOfAssets, setFileListOfAssets] = useState(false);
    // const [isInfoTabOpen, setIsInfoTabOpen] = useState(false)
    const [selectedFileObject, setSelectedFileObject] = useState();
    const [editedFileName, setEditedFileName] = useState();
    const [isClickEditName, setIsClickEditName] = useState(false);
    const [brandname, setBrandname] = useState()
    const [isSaveButton, setIsSaveButton] = useState(true)
    const [isUploadAssetGallery, setIsUploadAssetGallery] = useState(false)
    const [isDeletePopUpOPen, setIsDeletePopUpOPen] = useState()
    const [isCommentsPopUpOPen, setIsCommentsPopUpOPen] = useState()
    const [isExpandAiFile, setIsExpandAiFile] = useState(false)
    const [isUploadAiFIlesUi, setIsUploadAiFIlesUi] = useState(false)
    const [selectedGraphicFiles, setSelectedGraphicFiles] = useState([]);
    const [dragging, setDragging] = useState(false);
    const [alertMessageAi, setAlertMessageAi] = useState('');
    const [allOriginalFiles, setAllOriginalFiles] = useState([]);
    const [handleLinkFile, setHandleLinkFile] = useState(false);
    const [defaultSubCatid, setdefaultSubCatid] = useState();
    const [note, setNote] = useState();
    const [noteList, setNoteList] = useState([])
    const [fileForDelete, setFileForDelete] = useState('');
    const [fileType, setFileType] = useState();
    const [loadingButton,setLoadingButton]=useState(false)
    const [isDeleteConfirmPopUpOPen, setIsDeleteConfirmPopUpOPen] = useState();
    const [downloadAction,setDownloadAction]=useState(false)
    const [active,setActive]=useState(false)
    const [assetsName ,setAssetsName]= useState('')

    console.log('props')
    console.log(props)
    useEffect(() => {
        fetchGAFiles()
    }, []);
    const handleAssetsLibrery = (asset) => {
        setImageUrl(asset.filePath)
        setEditedFileName()
        setIsOpengalleryPopUp(true);
    }
    const fetchGAFiles = () => {
        let data = {
          assetIdentifier: props.assetIdentifier? props.assetIdentifier:''
        }
        getAllOriginalFilesByAssetIdentifierService(props.token, data).then((response) => {
          if (response) {
            console.log(response)
            setAllOriginalFiles(response)
          }
        })
      }
    const handleGADragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleGADragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
    };
    const getAssetNote = (value) => {
        setIsCommentsPopUpOPen(true)
        let data = {
            assetDetails: value.id
        }
        setAssetsName(value.actualFileName)
        getAssetNoteService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setNoteList(res)
            }
        })
    }
    const handleGADragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleGADrop = (e) => {
        setActive(true)
        e.preventDefault();
        setDragging(false);

        const files = Array.from(e.dataTransfer.files);
        const allowedExtensions = ['ai', 'psd', 'eps'];
        const filteredFiles = files.filter(file => {
            const extension = file.name.split('.').pop().toLowerCase();
            return allowedExtensions.includes(extension);
        });

        if (selectedGraphicFiles.length < 1) {
            setSelectedGraphicFiles(prevFiles => [...prevFiles, ...filteredFiles]);

            const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

            if (invalidFiles.length > 0) {
                setAlertMessageAi(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only ai, psd and eps files are allowed.`);
                setActive(false)
            } else {
                setAlertMessageAi('');
            }
        }

        else {
            setAlertMessageAi('You can only select one file at a time. Please remove the existing file before selecting a new one.');
        }
    };
    const handleSaveRename = (imageUrl) => {
        let data;
        if (imageUrl != '') {
            props.fileListOfAssetsVideo.map((img) => {
                if (img.filePath == imageUrl)
                    data = {
                        integerId: props.storeOverview.integerID,
                        assetCategory: props.assetCategory,
                        subCategoryId:props.subCategoryId,
                        assetIdentifier: selectedFileObject.assetIdentifier,
                        newName: editedFileName,
                        fileId: selectedFileObject.id
                    }
            })
        }
        else {
            data = {
                integerId: props.storeOverview.integerID,
                assetCategory: props.assetCategory,
                subCategoryId:props.subCategoryId,
                assetIdentifier: selectedFileObject.assetIdentifier,
                newName: editedFileName,
                fileId: selectedFileObject.id

            }
        }
        RenameFileAssetNewService(props.token, data).then((response) => {
            if (response) {
                response.fileList.map((file) => {
                    if (file.id === selectedFileObject.id) {
                        setSelectedFileObject(file)
                    }
                })
                setIsClickEditName(false)
                const imageFiles = response.fileList.filter(file => {
                    // Get the file extension by splitting the fileName and getting the last part
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    // Check if the extension is one of .jpg, .png, or .jpeg
                    return ['mp4', 'mov', 'avi', 'mkv'].includes(extension);
                });

                // Set the filtered list
                props.setFileListOfAssetsVideo(imageFiles);
                setEditedFileName()
                console.log(response)
            }
        })
    }
    const addAssetNote = () => {
        let data = {
            note: note,
            assetDetails: selectedFileObject.id
        }
        addAssetNoteService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setNoteList(res.noteList)
                setNote('')
                setIsCommentsPopUpOPen(false)

            }
        })
    }
    const handleDeleteFile = (imageUrl, fileTypeNew) => {
        let data;
        if (imageUrl != '') {
            if (fileType == 'image' || fileTypeNew == 'image') {
                props.fileListOfAssetsVideo.map((img) => {
                    if (img.filePath == imageUrl)
                        data = {
                            integerId: props.storeOverview.integerID,
                            assetCategory: props.assetCategory,
                            subCategoryId:props.subCategoryId,
                            assetIdentifier: selectedFileObject.assetIdentifier,
                            fileId: img.id,
                            fileType: fileType ? fileType : 'image'
                        }
                })
            }
            if (fileType == 'graphic') {
                props.fileListOfAssetsVideo.map((img) => {
                    if (img.originalGA != '') {
                        if (img.originalGA[0].filePath == imageUrl)
                            data = {
                                integerId: props.storeOverview.integerID,
                                assetCategory: props.assetCategory,
                                subCategoryId:props.subCategoryId,
                                assetIdentifier: selectedFileObject.assetIdentifier,
                                fileId: img.originalGA[0].id,
                                fileType: fileType
                            }
                    }

                })
            }

        }
        DeleteFileAssetNewService(props.token, data).then((response) => {
            if (response) {
                fetchGAFiles()
                setSelectedFileObject()
                const imageFiles = response.returnList.fileList.filter(file => {
                    // Get the file extension by splitting the fileName and getting the last part
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    // Check if the extension is one of .jpg, .png, or .jpeg
                    return ['mp4', 'mov', 'avi', 'mkv'].includes(extension);
                });

                // Set the filtered list
                props.setFileListOfAssetsVideo(imageFiles);
                console.log(response)
                console.log(imageFiles)
                // setFileListOfAssets(response.returnList.fileList)
                props.setIsInfoTabOpen(false)
                setIsOpengalleryPopUp(false)
                setFileType(false)
                setIsDeleteConfirmPopUpOPen(false)
            }
        })
    }
    const handleRenameFileAssets = () => {
        setIsClickEditName(true)
        setIsSaveButton(false)
    }
    const handleGraphicFileChange = (e) => {
        setActive(true)
        console.log(selectedGraphicFiles.length)
        const files = Array.from(e.target.files);

        const allowedExtensions = ['ai', 'psd', 'eps'];

        const filteredFiles = files.filter(file => {
            const extension = file.name.split('.').pop().toLowerCase();
            return allowedExtensions.includes(extension);
        });
        if (selectedGraphicFiles.length < 1) {
            setSelectedGraphicFiles(prevFiles => [...prevFiles, ...filteredFiles]);

            const invalidFiles = files.filter(file => !allowedExtensions.includes(file.name.split('.').pop().toLowerCase()));

            if (invalidFiles.length > 0) {
                setAlertMessageAi(`Invalid file(s) detected: ${invalidFiles.map(file => file.name).join(', ')}. Only ai, psd, and eps files are allowed.`);
            } else {
                setAlertMessageAi('');
            }
        }

        else {
            setAlertMessageAi('You can only select one file at a time. Please remove the existing file before selecting a new one.');
        }
    }
    const handleGraphicRemoveFile = (file) => {
        const updatedFiles = selectedGraphicFiles.filter((f) => f !== file);
        setSelectedGraphicFiles(updatedFiles);
    };
    const handleSubmitFile = () => {
        setLoadingButton(true)
        console.log(handleLinkFile);
        console.log(selectedGraphicFiles)
        if (handleLinkFile === false && selectedGraphicFiles.length < 1) {
            return true
        }

        const data = new FormData();
        data.append("integerId", props.storeOverview.integerID);
        data.append("assetCategory",props.assetCategory);
        data.append("subCategoryId", props.subCategoryId);
        data.append("assetIdentifier", props.assetIdentifier?props.assetIdentifier:'');
        data.append("notes", '');
        data.append("assetDetails", selectedFileObject.id);

        if (handleLinkFile !== false) {
            data.append("handleLinkFile", handleLinkFile);
            data.append("attachmentFiles1", 0);
        }
        else {
            for (let i = 0; i < selectedGraphicFiles.length; i++) {
                data.append("attachmentFiles1", selectedGraphicFiles[i]);
            }
            data.append("handleLinkFile", 0);
        }
        linkImageToGAFileService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setSelectedGraphicFiles([])
                setHandleLinkFile(false)
                const imageFiles = res.fileList.filter(file => {
                    // Get the file extension by splitting the fileName and getting the last part
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    // Check if the extension is one of .jpg, .png, or .jpeg
                    return ['mp4', 'mov', 'avi', 'mkv'].includes(extension);
                });

                // Set the filtered list
                props.setFileListOfAssetsVideo(imageFiles);
                // setFileListOfAssets(res.fileList);
                setIsUploadAiFIlesUi(false)
                props.handleOpenFileInfo()
                setLoadingButton(false)

            }
        })
    };
    const handleDownloadImage = async (url, type) => {
        setDownloadAction(type)
        if (type == "DownloadeImage") {
            try {
                // setDownloadAction(selectedFileObject.id,'DownloadeImage')
                // const response = await fetch(url);
                const response = await fetch(url, {
                    method: 'GET',
                    //mode: 'no-cors', 
                    referrerPolicy: 'no-referrer', // Set referrerPolicy here
                    cache: 'no-store',
                  });
                if (!response.ok) throw new Error(`Network response was not ok for ${selectedFileObject.id}`);

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = selectedFileObject.actualFileName || 'downloaded_file';

                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
                setDownloadAction(false)

            } catch (error) {
                setDownloadAction(false)
                console.error('Failed to download files:', error);
            }
        }
        else {
            try {
                // setDownloadAction(selectedFileObject.id,'DownloadeGraphicFile')

                const response = await fetch(url);
                if (!response.ok) throw new Error(`Network response was not ok for ${selectedFileObject.originalGA[0].id}`);

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = selectedFileObject.originalGA[0].actualFileName || 'downloaded_file';

                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
                setDownloadAction(false)
            } catch (error) {
                setDownloadAction(false)
                console.error('Failed to download files:', error);
            }
            
        }

    };
    const setImageInfo = (imageUrl) => {
        console.log(imageUrl)
        {
            props.fileListOfAssetsVideo.map((img) => {
                if (img.filePath == imageUrl) {
                    setSelectedFileObject(img)
                    setEditedFileName(img.actualFileName)
                }
            })
        }
    }
    const getImageExtension = (imageUrl) => {
        const matches = imageUrl.match(/\.(mp4|mov|avi|mkv)$/i);
        if (matches) {
            if (matches.length > 1) {
                return matches['input']
            }
            else {
                return null
            }
        }
        else {
            return null;
        }
    };
    const handleAssetsSlider = () => {
        const imageUrls = props.fileListOfAssetsVideo && props.fileListOfAssetsVideo.reduce((acc, fileUrl) => {
            if (getImageExtension(fileUrl.filePath) != null) {
                return acc.concat(fileUrl.filePath);
            }
            return acc;
        }, []);

        return <Carousel images={imageUrls} />;
    }
    const Carousel = ({ images }) => {
        const [currentSlide, setCurrentSlide] = useState(0);

        const nextSlide = () => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        };

        const prevSlide = () => {
            setCurrentSlide((prevSlide) =>
                prevSlide === 0 ? images.length - 1 : prevSlide - 1
            );
        };
        const filteredImageUrlArray = images.filter((url) => url.trim() !== '');
        const visibleImages = filteredImageUrlArray.slice(currentSlide, currentSlide + 5);
        return (
            <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary prev-next" disabled={currentSlide <= 0 ? true : false} onClick={prevSlide}>&lt; </button>
                {visibleImages.map((image, index) => (
                    <div className={`p-2 mt-2 mb-1 `} style={{ backgroundColor: imageUrl === image ? '#D0EFB1' : '' }}>
                        {image != null ?
                            <video key={index} height={80} width={80} src={image} alt={`Slide ${index + 1}`} onClick={() => { setImageUrl(image); setImageInfo(image); setIsClickEditName(false);   }} />
                            : ''}
                    </div>
                ))}
                <button className="btn btn-primary prev-next" disabled={currentSlide == filteredImageUrlArray.length - 5 ? true : false} onClick={nextSlide}> &gt;</button>
            </div>
        );
    };
    return (
        <>
            <Row>
                <Col md={props.isInfoTabOpen ? 6 : 12}>
                    <Row>
                        {props.fileListOfAssetsVideo && Object.values(props.fileListOfAssetsVideo).map((asset) => (

                            <Col sm={4} md={3} onDoubleClick={() => handleAssetsLibrery(asset)} onClick={() => { setIsExpandAiFile(false); setSelectedFileObject(asset); props.setIsInfoTabOpen(true); setEditedFileName(); setIsClickEditName(false);setActive(false) ; setLoadingButton(false) ; setIsUploadAiFIlesUi(false);setHandleLinkFile(false) }}>

                                <Card
                                    style={{ backgroundColor: props.isInfoTabOpen && selectedFileObject && selectedFileObject.id === asset.id ? '#D0EFB1' : '' }} className={`p-2 mt-2 mb-1 `}
                                >
                                    <div>
                                        { props.isInfoTabOpen ?
                                            <video 
                                            width="100%" 
                                            height="100%" 
                                            src={asset.filePath} 
                                            alt="Video not found"
                                            />:  
                                            <video 
                                            controls 
                                            width="100%" 
                                            height="100%" 
                                            src={asset.filePath} 
                                            alt="Video not found"
                                            />
                                            }
                                  
                                  
                                        {/* <img src={asset.filePath} alt='not found' width={'100%'} height={'100%'} /> */}
                                        {/*  <p>File name {selectedFileObject.actualFileName}</p>     */}
                                    </div>

                                </Card>
                            </Col>
                        ))
                        }
                    </Row>
                </Col>

                {props.isInfoTabOpen &&
                    (<Col md={6} className='assets-card-sticky'>
                        <Card className='p-3 child-card'>
                            {
                                selectedFileObject && (
                                    <>
                                        <div className='d-flex justify-content-end'>
                                            <button
                                                onClick={() => props.handleOpenFileInfo()}
                                                className='info-button-background-none'
                                                title='Close'>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </div>
                                        <div className='d-flex  justify-content-between mb-2'>
                                        </div>
                                        <p className='align-center '>
                                        <video 
                                                controls 
                                                width="100%" 
                                                height="100%" 
                                                src={selectedFileObject.filePath} 
                                                alt="Video not found"
                                            />
                                            {/* <img src={selectedFileObject.filePath} alt='not found' width={'100%'} height={'100%'} /> */}
                                        </p>
                                        <div className=''>
                                            <div className='d-flex align-items-center  '>

                                                {
                                                    isClickEditName ?
                                                        <>
                                                            <h6>
                                                                <Input
                                                                    className='w-100 '
                                                                    type='text'
                                                                    value={editedFileName ? editedFileName : selectedFileObject.actualFileName}
                                                                    onChange={(e) => setEditedFileName(e.target.value)}
                                                                />
                                                            </h6>&nbsp;
                                                            <h6>
                                                                <button
                                                                    title='save'
                                                                    className='info-button-background-none'
                                                                    onClick={() => handleSaveRename('')}
                                                                    disabled={!editedFileName}
                                                                >
                                                                    <img className='button-img-background-assets' src={TickMark} alt='not found' />
                                                                </button>
                                                               
                                                                <button
                                                                    title='Close'
                                                                    className='info-button-background-none'
                                                                    onClick={() => setIsClickEditName(false)}
                                                                >
                                                                    <FontAwesomeIcon color="red"  icon={faWindowClose} />
                                                                    {/* <img className='carousel-img-background-none p-1' src={Close} alt='not found' /> */}
                                                                </button>
                                                            </h6>
                                                        </>
                                                        : (<>
                                                            <h6> <b title={selectedFileObject.actualFileName} className='file-name'>{selectedFileObject.actualFileName}</b>&nbsp;
                                                                <button
                                                                    title='Edit'
                                                                    className='info-button-background-none'
                                                                    onClick={() => handleRenameFileAssets()}>
                                                                    <img className='button-img-background-assets p-1' src={Rename} alt='not found' />
                                                                </button></h6></>)
                                                }
                                                <button
                                                    onClick={() => handleDownloadImage(selectedFileObject.filePath, "DownloadeImage")}
                                                    className='info-button-background-none mb-1'
                                                    title='Download'
                                                >
                                                    <img className={`p-1 ${downloadAction=='DownloadeImage'?'button-img-background-download-inprogress':'button-img-background-download'}`} src={Download} alt='not found' />
                                                </button>
                                                {downloadAction=='DownloadeImage'?  <span>Downloading</span>:''}

                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <h6 className='d-flex justify-content-between'>
                                                    <p>{selectedFileObject.fileSize}</p>  </h6>
                                                <h6>   Last Modified on <b>{selectedFileObject.createdAt}</b>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className='box-border-upload-product'>
                                            <p>Editable Graphics Files
                                                {selectedFileObject.originalGA != '' ?
                                                    <span className='ml-1' style={{ color: 'green' }}> Available</span> :
                                                    <span className='ml-1' style={{ color: 'red' }}>Not Available</span>
                                                }
                                                <button
                                                    className='info-button-background-none float-right'
                                                    onClick={() => { setIsExpandAiFile(!isExpandAiFile); setIsUploadAiFIlesUi(false) }}>{isExpandAiFile ? (<FontAwesomeIcon icon={faAngleUp} />) : (<FontAwesomeIcon icon={faAngleDown} />)}
                                                </button>
                                            </p>
                                            {
                                                isExpandAiFile ?
                                                    <div>
                                                        {selectedFileObject.originalGA != '' ?
                                                            <>
                                                                <h6><b>{selectedFileObject.originalGA[0].actualFileName}</b>
                                                                 
                                                                    
                                                                    <button
                                                                        onClick={() => handleDownloadImage(selectedFileObject.originalGA[0].filePath, "GraphicFilenloade")}
                                                                        className='info-button-background-none mb-1'
                                                                        title='Download'
                                                                    >
                                                                        <img className={`p-1 ${downloadAction=='GraphicFilenloade'?'button-img-background-download-inprogress':'button-img-background-download'}`} src={Download} alt='not found' />
                                                                    </button>
                                                                    {downloadAction=='GraphicFilenloade'?  <span>Downloading</span>:''}</h6>
                                                                <div className='d-flex justify-content-between'>
                                                                    <h6 ><p>{selectedFileObject.originalGA[0].fileSize}</p></h6>
                                                                    <h6>  Last Modified on <b>{selectedFileObject.originalGA[0].updatedAt}</b>
                                                                    </h6>
                                                                </div>

                                                            </>
                                                            :
                                                            isUploadAiFIlesUi ?
                                                                <div

                                                                    className="p-4 ml-4 dotted-box-upload-product"
                                                                >
                                                                    <select className="form-control w-100 mb-3"

                                                                        onChange={(e) => {setHandleLinkFile(e.target.value);setActive(true)}}>
                                                                        <option value="">--Select Existing File--</option>
                                                                        {
                                                                            allOriginalFiles && allOriginalFiles.map((file) => (
                                                                                <option value={file.id}>{file.actualFileName}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {handleLinkFile ? '' :
                                                                        <>
                                                                            <h5 className='text-center'>OR</h5>
                                                                            <div
                                                                                onDrop={handleGADrop}
                                                                                onDragOver={handleGADragOver}
                                                                                onDragEnter={handleGADragEnter}
                                                                                onDragLeave={handleGADragLeave}
                                                                            >
                                                                                <label htmlFor="graphicFileInput" className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""}`}>
                                                                                    <h5>
                                                                                        <FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: "30px" }} />
                                                                                    </h5>
                                                                                    <h5>Drag &amp; Drop Files </h5>
                                                                                    <h5>OR</h5>
                                                                                    <input type="file" id="graphicFileInput" name="" className="custom-brand-file" onChange={handleGraphicFileChange} style={{ display: "none" }} />

                                                                                    <Button onClick={() => document.getElementById("graphicFileInput").click()} style={{ background: "#B2A1FF" }}>
                                                                                        Browse Files
                                                                                    </Button>
                                                                                </label>
                                                                                <p style={{ color: 'red' }}>{alertMessageAi}</p>
                                                                                <p style={{ fontSize: "13px" }}>Attached File List</p>
                                                                                <ul style={{ color: "green", fontSize: "13px" }}>
                                                                                    {selectedGraphicFiles.length > 0 ? (
                                                                                        selectedGraphicFiles.map((file, index) => (
                                                                                            <li key={index}>
                                                                                                {file.name}
                                                                                                <button className="btn" onClick={() => handleGraphicRemoveFile(file)}>
                                                                                                    <FontAwesomeIcon color="red" size="15px" icon={faWindowClose} />
                                                                                                </button>
                                                                                            </li>
                                                                                        ))
                                                                                    ) : (
                                                                                        <li>No files attached</li>
                                                                                    )}
                                                                                </ul>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    <div className='d-flex justify-content-center m-3'>
                                                                        <Button className='btn-background-color-Proceed-gallery'
                                                                         disabled={!(active && !loadingButton) || alertMessageAi ? true : false}
                                                                        onClick={() => handleSubmitFile()}>
                                                                        {loadingButton?'Submitting':'Submit'}</Button>
                                                                    </div>
                                                                </div>
                                                                : <div>
                                                                    <h6 onClick={() => setIsUploadAiFIlesUi(true)}
                                                                        className='text-center'
                                                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}>Upload File
                                                                        <img className='ml-2' src={UploadButton_Dark} alt='not found' /></h6>
                                                                </div>
                                                        }
                                                    </div> : ''
                                            }
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center'>
                                                <Button onClick={() => setIsDeletePopUpOPen(true)} className='delete-button-background-none mt-1'>
                                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                                </Button>
                                            </div>
                                            <div className='ml-auto'>
                                                <button onClick={() => getAssetNote(selectedFileObject)} className='btn mt-1'>
                                                    <img className="comments-bg-color" src={Comment_Dark} alt='not found' />
                                                </button>
                                            </div>
                                        </div>
                                    </>)
                            }
                        </Card>
                    </Col>)
                }
            </Row>
            <CustomModal
                isOpen={isDeletePopUpOPen}
                toggle={() => { setIsDeletePopUpOPen(!isDeletePopUpOPen); setFileForDelete(false); }}
                size={"md"}
                className="curve-popup"
            >
                <Card className="curve-popup p-3 ">
                    <Row>
                        <Col md={12}>
                            <div className='ml-4'>
                                <p>Which file do you want to delete?</p>
                                <label className='ml-2 '>
                                    <input
                                        type='radio'
                                        name='fileType'
                                        onChange={() => { setFileForDelete(selectedFileObject ? selectedFileObject.filePath : ''); setFileType('image'); }}
                                    />&nbsp;
                                    Video File : {selectedFileObject ? selectedFileObject.actualFileName : ''}
                                </label>
                                {selectedFileObject ? selectedFileObject.originalGA != '' ?
                                    <label className='ml-2'>
                                        <input
                                            type='radio'
                                            name='fileType'
                                            onChange={() => { setFileForDelete(selectedFileObject ? selectedFileObject.originalGA != '' ? selectedFileObject.originalGA[0].filePath : '' : ''); setFileType('graphic'); }}
                                        />&nbsp;
                                        Editable File : {selectedFileObject ? selectedFileObject.originalGA != '' ? selectedFileObject.originalGA[0].actualFileName : 'Not Availble' : ''}
                                    </label> : '' : ''}
                            </div>
                            <div className='mb-2 ml-4'>
                                <Button
                                    onClick={() => { setIsDeleteConfirmPopUpOPen(true); setIsDeletePopUpOPen(false) }}
                                    className='delete-button-background-none mt-1'
                                    disabled={fileForDelete == '' ? true : false}>
                                    <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                    Delete
                                </Button> &nbsp;
                                <Button
                                    onClick={() => { setFileForDelete(false); setIsDeletePopUpOPen(!isDeletePopUpOPen) }}
                                    className=' mt-1'>
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </CustomModal>
            <CustomModal
                    isOpen={isDeleteConfirmPopUpOPen}
                    toggle={() => { setIsDeleteConfirmPopUpOPen(!isDeleteConfirmPopUpOPen); setFileForDelete(false); }}
                    size={"md"}
                    className="curve-popup"
                >
                    <Card className="curve-popup p-3 ">
                        <Row>
                            <Col md={12}>
                                <div>
                                    <h6>
                                        Are you sure you want to delete file?
                                    </h6>
                                </div>
                                <div className='mb-2'>
                                    <Button
                                        onClick={() => handleDeleteFile(fileForDelete,'false')}
                                        className='delete-button-background-none mt-1'
                                        disabled={fileForDelete == '' ? true : false}>
                                        <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                        Delete
                                    </Button> &nbsp;
                                    <Button
                                        onClick={() => { setFileForDelete(false); setIsDeleteConfirmPopUpOPen(!isDeleteConfirmPopUpOPen) }}
                                        className=' mt-1'>
                                        Cancel
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </CustomModal>
                <CustomModal
                    isOpen={isOpengalleryPopUp}
                    toggle={() => { setIsOpengalleryPopUp(!isOpengalleryPopUp); setIsClickEditName(false) }}
                    size={"lg"}
                    className="asset-carousel"
                >
                    <Row
                        className={`m-1 ${isAssetPopUp ? 'd-none' : ''}`}>
                        <Col md={12} className=''>
                            {
                                selectedFileObject && (
                                    <div className='d-flex justify-content-between mb-2'>
                                        <div className='d-flex align-items-center mb-2'>
                                            {/* File Name */}
                                            <h6 style={{ color: '#fff' }}>
                                                <b>File Name : </b>&nbsp;
                                            </h6>
                                            {/* Edit File Name Input */}
                                            {isClickEditName ? (
                                                <>
                                                    <Input
                                                        className='w-50'
                                                        type='text'
                                                        value={editedFileName ? editedFileName : selectedFileObject.actualFileName}
                                                        onChange={(e) => setEditedFileName(e.target.value)}
                                                    />&nbsp;
                                                    <h6>
                                                        <button
                                                            title='save'
                                                            className='info-button-background-none'
                                                            onClick={() => handleSaveRename(imageUrl)}
                                                            disabled={!editedFileName}
                                                        >
                                                            <img className='carousel-img-background-none' src={TickMark} alt='not found' />
                                                        </button>

                                                        <button
                                                            title='Close'
                                                            className='info-button-background-none'
                                                            onClick={() => setIsClickEditName(false)}
                                                        >
                                                            <FontAwesomeIcon color="red"  icon={faWindowClose} />
                                                            {/* <img className='carousel-img-background-none p-1' src={Close} alt='not found' /> */}
                                                        </button>

                                                    </h6>
                                                </>
                                            ) : (
                                                <h6 style={{ color: '#fff' }}><b  className='file-name'>
                                                    {editedFileName ? editedFileName : selectedFileObject.actualFileName} &nbsp;</b>
                                                    <button
                                                        title='Edit'
                                                        className='info-button-background-none'
                                                        onClick={() => handleRenameFileAssets()}
                                                    >
                                                        <img className='carousel-img-background-none p-1' src={Rename} alt='not found' />
                                                    </button>
                                                </h6>
                                            )}
                                            {/* Download Button */}
                                            <h6>
                                                
                                                <button
                                                   onClick={() => handleDownloadImage(selectedFileObject.filePath, "DownloadeImage")}
                                                    className='info-button-background-none'
                                                    title='Download'
                                                >
                                                    <img className={`carousel-img-background-none-download p-1 ${downloadAction=='DownloadeImage'?'button-img-background-download-inprogress':'button-img-background-download'}`} src={Download} alt='not found' />
                                                </button>
                                                {downloadAction =='DownloadeImage'?  <span>Downloading</span>:''}

                                            </h6>
                                        </div>
                                        {/* Close Button */}
                                        <div className='ml-auto'>
                                            <button
                                                onClick={() => { setIsOpengalleryPopUp(false); setIsClickEditName(false) }}
                                                className='info-button-background-none '
                                                title='Close'
                                            >
                                                <img src={Close} alt='not found' />
                                                {/* <img className=' text-white p-1' src={Close} alt='not found' /> */}
                                            </button>
                                        </div>
                                    </div>

                                )}
                            <div className='file-view-div'>
                                <video
                                    controls 
                                    src={imageUrl}
                                    style={{ background: '#fff' }}
                                    title="File Display"
                                    className='file-view p-2'
                                />
                                <h6 className='d-flex justify-content-center delete-btn-popup' >
                                    <Button
                                        onClick={() => {handleDeleteFile(imageUrl,'image');}}
                                        className='delete-button-background-none mt-1'>
                                        <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                        Delete
                                    </Button>
                                </h6>
                            </div>
                        </Col>
                        <Col md={12} className=''>
                            {handleAssetsSlider()}
                        </Col>
                    </Row>
                </CustomModal>
                <CustomModal
                    isOpen={isCommentsPopUpOPen}
                    toggle={() => setIsCommentsPopUpOPen(false)}
                    size={"md"}
                    header={<Label title={assetsName} className='file-name'>{assetsName}</Label>}
                    className="curve-popup position-of-pop-up"
                >
                    <Row>
                        <Col md={12} className='p-0'>
                            <div>
                                {selectedFileObject && (
                                    <p className='align-center '>
                                        <video 
                                                controls 
                                                width="100%" 
                                                height="100%" 
                                                src={selectedFileObject.filePath} 
                                                alt="Video not found"
                                            />
                                        {/* <img src={selectedFileObject.filePath} alt='not found' width={'100%'} height={'100%'} /> */}
                                    </p>
                                )}
                            </div>
                        </Col>
                        <Col md={12} className="scrollable-div p-0 overflow-auto" style={{ maxHeight: '187px' }}>
                            {noteList && noteList.map((note) => (<>
                                {note.loggedInUser === 1 ?
                                    (<div className='d-flex justify-content-end p-2'>
                                        <p className='p-2 mb-0 d-inline chat-background-color-sent'>
                                            {note.notes} <br></br>
                                            <span className='float-right pt-1'>
                                                {note.createdAt}
                                            </span>
                                        </p>
                                    </div>) : <div className='d-flex justify-content-start align-items-end p-2'>
                                        {/* <img src={User} title={note.userName} className='pr-2' />  */}
                                        <div className='p-1 user-short-name'>{note.userShortName.toUpperCase()}</div>
                                        <p className='d-inline p-2 mb-0 chat-background-color '>
                                            {note.notes}
                                            <br></br>
                                            <span className='float-right pt-1'>
                                                {note.createdAt}
                                            </span>
                                        </p>
                                    </div>}</>)
                            )
                            }
                        </Col>
                        <Col md={12} className='d-flex align-items-between pl-2 pr-2 pt-1'>
                            <Input className='w-100 '
                                style={{ borderradius: '10px' }}
                                placeholder="Add your comments"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                            <button className='btn-background-color-comments' 
                            disabled={!note}
                            onClick={() => { addAssetNote() }}><img src={Send} alt='not found' /></button>
                        </Col>
                    </Row>
                </CustomModal>
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};

export default connect(mapStateToProps)(VideosForBrand)