import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";

import DashboardSidebar from "./Sidebar/DashboardSidebar";
import PageTitle from "./PageTitle";
import DashboardFooter from "./Footer/DashboardFooter";


function DashboardLayout(props) {
console.log(props)
    let location = useLocation();
    let history = useHistory();

    const [menuURLs, setMenuURLs] = useState([]);
    const [filterMenuList,setFilterMenuList]= useState();
    useEffect(() => {
        setMenuURLs(Array.from(props.menuItems, item => item.to));
        const filterMenuItems=props.menuItems.filter((item)=>{
            const filterPermission =permissionsArray.find((perm)=>(
                perm.text ===item.label && perm.value === true
            ))
            return filterPermission !== undefined
        })
        setFilterMenuList(filterMenuItems)
    }, [props.menuItems]);

    const currentPageIndex = menuURLs.indexOf(location.pathname);
    const hasNextPage = (currentPageIndex > -1) && (currentPageIndex < menuURLs.length - 1);
    const hasPreviousPage = (currentPageIndex > 0)

    const nextPage = () => {
        const nextPageIndex = currentPageIndex + 1;
        if (nextPageIndex === menuURLs.length) return;
        history.push(menuURLs[nextPageIndex]);
    }

    const previousPage = () => {
        const previousPageIndex = currentPageIndex - 1;
        if (previousPageIndex < 0) return;
        history.push(menuURLs[previousPageIndex]);
    }
console.log(props.loggedUser)
    const permissionsArray=[
        {
            id:1,
            text:'Campaign Launch',
            value: props.loggedUser ? props.loggedUser.Operations_CampaignLaunch : false
        },
        {
            id:2,
            text:'Advertising API Jobs',
            value: props.loggedUser ? props.loggedUser.Operations_AdvertisingAPIJobs : false
        },
        {
            id:3,
            text:'Workflows',
            value: props.loggedUser ? props.loggedUser.Operations_Workflows : false
        },
        {
            id:4,
            text:'Store Health',
            value: props.loggedUser ? props.loggedUser.Operations_Storehealth : false
        },
        {
            id:5,
            text:'Resource Skill Set',
            value: props.loggedUser ? props.loggedUser.Operations_ResourceSkillSet : false
        },
        {
            id:6,
            text:'Ticket Assignment',
            value: props.loggedUser ? props.loggedUser.Operations_TicketAssignement : false
        }
    ]
    return (
        <Fragment>
            <div className="app-main">
                <DashboardSidebar menuItems={filterMenuList} heading={props.heading} />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        {/* <PageTitle
                            heading={props.heading}
                            subheading={props.subheading}
                            icon={props.icon}
                        > */}
                            {/*<div className="page-title-actions">*/}
                            {/*    <Button*/}
                            {/*        className="mr-2 btn-shadow btn-icon btn-icon-only"*/}
                            {/*        color="link"*/}
                            {/*        size="lg"*/}
                            {/*        style={{marginLeft: 'auto', padding: 0}}*/}
                            {/*        onClick={previousPage}*/}
                            {/*        disabled={!hasPreviousPage}*/}
                            {/*    >*/}
                            {/*        <i*/}
                            {/*            className="lnr-arrow-left-circle btn-icon-wrapper"*/}
                            {/*            style={{fontSize: '40px'}}*/}
                            {/*        >*/}
                            {/*        </i>*/}
                            {/*    </Button>*/}
                            {/*    <Button*/}
                            {/*        className="mr-2 btn-shadow btn-icon btn-icon-only"*/}
                            {/*        color="link"*/}
                            {/*        size="lg"*/}
                            {/*        style={{marginLeft: 'auto', padding: 0}}*/}
                            {/*        onClick={nextPage}*/}
                            {/*        disabled={!hasNextPage}*/}
                            {/*    >*/}
                            {/*        <i*/}
                            {/*            className="lnr-arrow-right-circle btn-icon-wrapper"*/}
                            {/*            style={{fontSize: '40px'}}*/}
                            {/*        >*/}
                            {/*        </i>*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        {/* </PageTitle> */}
                        {props.children}
                    </div>
                    <DashboardFooter />
                </div>
            </div>
        </Fragment>
    );
}

export default DashboardLayout;
