import React, { Fragment } from "react";

import { CUSTOMER_SUPPORT_EMAIL } from "../../../../Utils/Constants";


function DashboardFooter() {
    return (
        <Fragment>
            <div className="app-footer" style={{height: '40px'}}>
                <div className="app-footer__inner">
                    <div className="mb-2">
                    </div>
                    <div className="app-footer-right mb-2">
                    <footer> <small>&copy; Copyright {new Date().getFullYear()}, Simpliworks.io</small> </footer>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardFooter;
