import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadRegistrastionJournyData } from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';

function GetAllUsersAndRolesByStoreService(token,data) {
    return axios.post(`${CURRENT_API_URL}/account/getAllUsersAndRolesByStore`, data, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching data. please try again later.");
        return false;
    });
}


export default GetAllUsersAndRolesByStoreService;