import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import {LoadRegistrastionJournyData} from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';

function CalculateSWTeamMemberTicketPerformanceService(token,data) {
    return axios.post(`${CURRENT_API_URL}/swinternalsystem/calculateSWTeamMemberTicketPerformance`, data,{
    headers: {
        'Authorization': token,
    }
    }).then(res => {
        notify.NotifySuccess("Assigned ticket accepted successfully. Thank You!");       
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while Assigned ticket accept. please try again later.");
        return false;
    });
}

export default CalculateSWTeamMemberTicketPerformanceService;