import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Input, Button, Label } from 'reactstrap'
import { connect } from 'react-redux';
import CustomModal from '../../../../../../../../../../Components/Modals/CustomModal';
import Rename from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Rename.svg'
import Comment_Dark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Comment_Dark.svg'
import TickMark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/TickMark.svg'
import Download from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Download.svg'
import xlsxIcon from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/xlsxIcon.svg'
import Close from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Close.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faAngleDown, faAngleUp, faCloudUploadAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Send from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Send.svg'
import addAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/addAssetNoteService';
import getAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/getAssetNoteService';
import RenameFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/RenameFileAssetNewService';
import DeleteFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/DeleteFileAssetNewService';
import ReactTable from 'react-table';
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
function OtherBrandAsset(props) {
  
    const [fileListOfAssets, setFileListOfAssets] = useState(false);
    const [isInfoTabOpen, setIsInfoTabOpen] = useState(false)
    const [selectedFileObject, setSelectedFileObject] = useState();
    const [isDeletePopUpOPen, setIsDeletePopUpOPen] = useState()
    const [isCommentsPopUpOPen, setIsCommentsPopUpOPen] = useState()
    const [note, setNote] = useState();
    const [noteList, setNoteList] = useState([])
    const [fileForDelete, setFileForDelete] = useState('');
    const [fileType, setFileType] = useState();
    const [isDeleteConfirmPopUpOPen, setIsDeleteConfirmPopUpOPen] = useState();
    const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [isFileNamePopUpOPen,setIsFileNamePopUpOPen]=useState(false)
    const [fileName, setFileName]=useState('')
    const [demoImage,setDemoImage]=useState()
    const [downloading, setdownloading] = useState(false)
    const [assetsName ,setAssetsName]= useState('')


    const getAssetNote = (id, path, file,name) => {
        setIsCommentsPopUpOPen(true)
        let data = {
            assetDetails: id
        }
        setAssetsName(name)
        getAssetNoteService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setNoteList(res)
            }
            showNote(path,file)
        })
    }

   
    const addAssetNote = () => {
        let data = {
            note: note,
            assetDetails: selectedFileObject.id
        }
        addAssetNoteService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                setNoteList(res.noteList)
                setNote('')
                setIsCommentsPopUpOPen(false)

            }
        })
    }
    const handleDeleteFile = (imageUrl, fileTypeNew) => {
        let data;
        if (imageUrl != '') {
            if (fileType == 'image' || fileTypeNew == 'image') {
                props.allFileListOfAssets.map((img) => {
                    if (img.filePath == imageUrl)
                        data = {
                            integerId: props.storeOverview.integerID,
                            assetCategory: props.assetCategory,
                            subCategoryId:props.subCategoryId,
                            assetIdentifier: selectedFileObject.assetIdentifier,
                            fileId: img.id,
                            fileType: fileType ? fileType : 'image'
                        }
                })
            }
            if (fileType == 'graphic') {
                props.allFileListOfAssets.map((img) => {
                    if (img.originalGA != '') {
                        if (img.originalGA[0].filePath == imageUrl)
                            data = {
                                integerId: props.storeOverview.integerID,
                                assetCategory: props.assetCategory,
                                subCategoryId:props.subCategoryId,
                                assetIdentifier: selectedFileObject.assetIdentifier,
                                fileId: img.originalGA[0].id,
                                fileType: fileType
                            }
                    }

                })
            }

        }
        DeleteFileAssetNewService(props.token, data).then((response) => {
            if (response) {
                setSelectedFileObject()
                setFileListOfAssets()
                const imageFiles = response.returnList.fileList.filter(file => {
                    // Get the file extension by splitting the fileName and getting the last part
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    // Check if the extension is one of .jpg, .png, or .jpeg
                    return ['jpg', 'png', 'jpeg'].includes(extension);
                });

                // Set the filtered list
                props.setFileListOfAssets(imageFiles);
                console.log(response)
                props.setAllFileListOfAssets(response.returnList.fileList)
                console.log(imageFiles)
                // setFileListOfAssets(response.returnList.fileList)
                setIsInfoTabOpen(false)
                setFileForDelete('')
                setFileType(false)
                setIsDeleteConfirmPopUpOPen(false)
            }
        })
    }

    
    const show = (path,fullPath) => {
        console.log(path);
        // return true
        const separatedPath = path.split("/");
        const ext = path.split(".")[1];
        if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx"|| ext == "doc" || ext == 'xlsm') {
          setFilePath(fullPath);
          setIsAttachmentOpen(true);
          setTimeout(() => {
            setIsAttachmentOpen(false);
          }, 200);
        } else {
          setFilePath(fullPath);
          setIsAttachmentOpen(true);
        }
      };
      const showNote = (path,fullPath) => {
        console.log(path);
        // return true
        // const separatedPath = path.split("/");
        const ext = path.split(".")[1];
        if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx"|| ext == "doc" || ext == 'xlsm') {
        //   setFilePath(fullPath);
        //   setIsAttachmentOpen(false);
          setDemoImage(true)
        } else {
          setFilePath(fullPath);
          setDemoImage(false)

        //   setIsAttachmentOpen(true);
        }
      };
     
    const handleDownloadImage = async (url, type,fileObject) => {
        if (type == "DownloadeImage") {
            try {
                setdownloading(fileObject.id)
                // const response = await fetch(url);
                const response = await fetch(url, {
                    method: 'GET',
                    //mode: 'no-cors', 
                    referrerPolicy: 'no-referrer', // Set referrerPolicy here
                    cache: 'no-store',
                  });
                if (!response.ok) throw new Error(`Network response was not ok for ${fileObject.id}`);
                    console.log(fileObject)
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = fileObject.actualFileName || 'downloaded_file';
                console.log(downloadLink)
                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
                setdownloading(false)
            } catch (error) {
                console.error('Failed to download files:', error);
                setdownloading(false)
            }
        }
        else {
            try {
                setdownloading(fileObject.id)
                const response = await fetch(url);
                if (!response.ok) throw new Error(`Network response was not ok for ${fileObject.originalGA[0].id}`);

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                console.log(fileObject)
                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = fileObject.originalGA[0].actualFileName || 'downloaded_file';
                console.log(downloadLink)
                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
                setdownloading(false)
            } catch (error) {
                console.error('Failed to download files:', error);
                setdownloading(false)

            }
        }

    };
    const handleDownloadImagePopup = async (url ) => {
      
            try {
                setdownloading(selectedFileObject.id)
                // const response = await fetch(url);
                const response = await fetch(url, {
                    method: 'GET',
                    //mode: 'no-cors', 
                    referrerPolicy: 'no-referrer', // Set referrerPolicy here
                    cache: 'no-store',
                  });
                if (!response.ok) throw new Error(`Network response was not ok for ${selectedFileObject.id}`);
                    console.log(selectedFileObject)
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = selectedFileObject.actualFileName || 'downloaded_file';
                console.log(downloadLink)
                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
                setdownloading(false)
            } catch (error) {
                console.error('Failed to download files:', error);
                setdownloading(false)
            }
        }
      

  
   
    const fileExtensions = ['csv', 'docx', 'xlsx', 'xls','doc','xlsm'];
    const handleFileName=(file)=>{
        setFileName(file)
        setIsFileNamePopUpOPen(true)
    }
    const columns = [
        // {
        //     Header:'Id',
        //     accessor:'id',
        //     Cell: (cellInfo) => (
        //       <div className="d-flex justify-content-center w-100 ">
        //         <p className="m-0">{cellInfo.value}</p>
        //       </div>
        //     ),
        //   },
          {
            Header:'File Name',
            accessor:'actualFileName',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0 comment-paragraph" onClick={()=>handleFileName(cellInfo.value)}>{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'File Type',
            accessor:'fileType',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0 comment-paragraph">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Uploaded At',
            accessor:'createdAt',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Uploaded By',
            accessor:'userName',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 ">
                <p className="m-0">{cellInfo.value}</p>
              </div>
            ),
          },
          {
            Header:'Note',
            accessor:'id',
            Cell: (cellInfo) => (
              <div className="d-flex justify-content-center w-100 cursor-pointer">
                <button onClick={() =>{getAssetNote(cellInfo.value, cellInfo.original.fileName,cellInfo.original.filePath,cellInfo.original.actualFileName); setSelectedFileObject(cellInfo.original); }} className='btn mt-1'>
                    <img className="comments-bg-color" height={25} width={25} src={Comment_Dark} alt='not found' />
                </button>
              </div>
            ),
          },
          {
            Header:'Actions',
            accessor:'actualFileName',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  {
                        fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                      <>
                        <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            disabled={downloading}
                            className={`ml-3 ${downloading == cellInfo.original.id?'button-img-background-download-inprogress':''}`}
                            icon={faDownload}
                            onClick={() => {setSelectedFileObject(cellInfo.original);handleDownloadImage(cellInfo.original.filePath, "DownloadeImage",cellInfo.original);}}
                        />
                      <FontAwesomeIcon onClick={() => {setIsDeletePopUpOPen(true);setSelectedFileObject(cellInfo.original)}} icon={faTrash} className="ml-3" style={{ cursor: 'pointer' }}/>
                        </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          className="ml-3"
                          style={{ cursor: 'pointer' }}
                          icon={faEye}
                          onClick={() => {show(cellInfo.original.fileName,cellInfo.original.filePath);setSelectedFileObject(cellInfo.original)}}
                        />
                        <FontAwesomeIcon
                       
                          style={{ cursor: 'pointer' }}
                          disabled={downloading}
                          className={`ml-3 ${downloading == cellInfo.original.id?'button-img-background-download-inprogress':''}`}
                          icon={faDownload}
                          onClick={() => {handleDownloadImage(cellInfo.original.filePath, "DownloadeImage",cellInfo.original);setSelectedFileObject(cellInfo.original)}}
                        />
                        <FontAwesomeIcon onClick={() => {setIsDeletePopUpOPen(true);setSelectedFileObject(cellInfo.original)}} icon={faTrash} className="ml-3" style={{ cursor: 'pointer' }}/>
                      </>
                    )
                  }

                </div>
              ),
          },
    ]

    return (
        <>
            <Row>
                
               <Col md={12}>
               <ReactTable
                data={props.allFileListOfAssets}
                columns={columns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={5}
                noDataText={"No Data Found."}
                />
               </Col>
               
            </Row>
            <CustomModal
                isOpen={isDeletePopUpOPen}
                toggle={() => { setIsDeletePopUpOPen(!isDeletePopUpOPen); setFileForDelete(false); }}
                size={"md"}
                className="curve-popup"
            >
                <Card className="curve-popup p-3 ">
                    <Row>
                        <Col md={12}>
                            <div className='ml-4'>
                                <p>Which file do you want to delete?</p>
                                <label className='ml-2 '>
                                    <input
                                        type='radio'
                                        name='fileType'
                                        onChange={() => { setFileForDelete(selectedFileObject ? selectedFileObject.filePath : ''); setFileType('image'); }}
                                    />&nbsp;
                                    File : {selectedFileObject ? selectedFileObject.actualFileName : ''}
                                </label>
                                {selectedFileObject ? selectedFileObject.originalGA != '' ?
                                    <label className='ml-2'>
                                        <input
                                            type='radio'
                                            name='fileType'
                                            onChange={() => { setFileForDelete(selectedFileObject ? selectedFileObject.originalGA != '' ? selectedFileObject.originalGA[0].filePath : '' : ''); setFileType('graphic'); }}
                                        />&nbsp;
                                        Editable File : {selectedFileObject ? selectedFileObject.originalGA != '' ? selectedFileObject.originalGA[0].actualFileName : 'Not Availble' : ''}
                                    </label> : '' : ''}
                            </div>
                            <div className='mb-2 ml-4'>
                                <Button
                                    onClick={() => { setIsDeleteConfirmPopUpOPen(true); setIsDeletePopUpOPen(false) }}
                                    className='delete-button-background-none mt-1'
                                    disabled={fileForDelete == '' ? true : false}>
                                    <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                    Delete
                                </Button> &nbsp;
                                <Button
                                    onClick={() => { setFileForDelete(false); setIsDeletePopUpOPen(!isDeletePopUpOPen) }}
                                    className=' mt-1'>
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </CustomModal>
            <CustomModal
                    isOpen={isDeleteConfirmPopUpOPen}
                    toggle={() => { setIsDeleteConfirmPopUpOPen(!isDeleteConfirmPopUpOPen); setFileForDelete(false); }}
                    size={"md"}
                    className="curve-popup"
                >
                    <Card className="curve-popup p-3 ">
                        <Row>
                            <Col md={12}>
                                <div>
                                    <h6>
                                        Are you sure you want to delete file?
                                    </h6>
                                </div>
                                <div className='mb-2'>
                                    <Button
                                        onClick={() => handleDeleteFile(fileForDelete,'false')}
                                        className='delete-button-background-none mt-1'
                                        disabled={fileForDelete == '' ? true : false}>
                                        <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                        Delete
                                    </Button> &nbsp;
                                    <Button
                                        onClick={() => { setFileForDelete(false); setIsDeleteConfirmPopUpOPen(!isDeleteConfirmPopUpOPen) }}
                                        className=' mt-1'>
                                        Cancel
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </CustomModal>
                <CustomModal
                    isOpen={isCommentsPopUpOPen}
                    toggle={() => setIsCommentsPopUpOPen(false)}
                    size={"md"}
                    header={<Label title={assetsName} className='file-name'>{assetsName}</Label>}
                    className="curve-popup position-of-pop-up"
                >
                    <Row>
                        <Col md={12} className='p-0'>
                            {
                                demoImage?
                                <div className='xlx-file'>
                                    <img src={xlsxIcon}/>
                                    <h5><b>Preview Not Available</b></h5>
                                </div>
                                :
                                <div>
                                <iframe
                                    src={filePath}
                                    title="File Display"
                                    width="100%"
                                    height="250"
                                    ></iframe>
                                </div>
                            }
                           
                        </Col>
                        <Col md={12} className="scrollable-div p-0 overflow-auto" style={{ maxHeight: '187px' }}>
                            {noteList && noteList.map((note) => (<>
                                {note.loggedInUser === 1 ?
                                    (<div className='d-flex justify-content-end p-2'>
                                        <p className='p-2 mb-0 d-inline chat-background-color-sent'>
                                            {note.notes} <br></br>
                                            <span className='float-right pt-1'>
                                                {note.createdAt}
                                            </span>
                                        </p>
                                    </div>) : <div className='d-flex justify-content-start align-items-end p-2'>
                                        {/* <img src={User} title={note.userName} className='pr-2' />  */}
                                        <div className='p-1 user-short-name'>{note.userShortName.toUpperCase()}</div>
                                        <p className='d-inline p-2 mb-0 chat-background-color '>
                                            {note.notes}
                                            <br></br>
                                            <span className='float-right pt-1'>
                                                {note.createdAt}
                                            </span>
                                        </p>
                                    </div>}</>)
                            )
                            }
                        </Col>
                        <Col md={12} className='d-flex align-items-between pl-2 pr-2 pt-1'>
                            <Input className='w-100 '
                                style={{ borderradius: '10px' }}
                                placeholder="Add your comments"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                            <button className='btn-background-color-comments'
                            disabled={!note}
                             onClick={() => { addAssetNote() }}><img src={Send} alt='not found' /></button>
                        </Col>
                    </Row>
                </CustomModal>
                <CustomModal
                    header={<> Attachment
                    <FontAwesomeIcon className={`ml-3 ${downloading?'button-img-background-download-inprogress':''}`} style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => handleDownloadImagePopup(filePath, "DownloadeImage")} />
                    </>}
                    isOpen={isAttachmentOpen}
                    toggle={() => {setIsAttachmentOpen(!isAttachmentOpen);setdownloading(false)}}
                    size={"lg"}
                    className='curve-popup'
                    footer={
                    <>
                        <Button
                        color={"link"}
                        onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
                        >
                        Cancel
                        </Button>
                    </>
                    }
                >
                    <Label>
                    <div>
                        <iframe
                        src={filePath}
                        title="File Display"
                        width="100%"
                        height="500"
                        ></iframe>
                    </div>
                    </Label>
                </CustomModal>
                <CustomModal
                    isOpen={isFileNamePopUpOPen}
                    toggle={() => setIsFileNamePopUpOPen(false)}
                    size={"sm"}
                    className="curve-popup"
                >
                    <Card className='curve-popup p-3'>
                        <Col md={12}>
                        <p>{fileName}</p>
                        </Col>
                    </Card>
                </CustomModal>

        </>

    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};

export default connect(mapStateToProps)(OtherBrandAsset)