// src/Navbar.js
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import GetAllMainRequestTypesService from "../../../../../../Service/DashboardServices/HeplDeskServices/GetAllMainRequestTypesService";
import StoreOverviewDoThingsItems from "./StoreOverviewDoThingsItems";
import GetAllProblemAreas from "./GetAllProblemAreas";
import BuildYourBrandFrame1 from "./SubComponents/BuildYourBrandFrame1";
import Buildyourbrands_icon from "../../../../../../designUtils/Storeoverview/Dothings/Buildyourbrands_icon.svg"
import View_Insinghts from "../../../../../../designUtils/Storeoverview/Dothings/view_insinghts.svg"
import Launchsomthingnew_icon from "../../../../../../designUtils/Storeoverview/Dothings/Launchsomthingnew_icon.svg"
import Solveproblems_icon from "../../../../../../designUtils/Storeoverview/Dothings/Solveproblems_icon.svg"
import ArrowIcon_icon from "../../../../../../designUtils/Logo/Vector_Arrow.svg"
import { height } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { CURRENT_API_URL } from "../../../../../../Utils/Constants";

const resolveIconImage = (requestType) => {
  console.log(requestType)
  const iconsMap = {
    "Build Your Brand": Buildyourbrands_icon,
    "Solve Problems": Solveproblems_icon,
    "Launch Something New": Launchsomthingnew_icon,
    "View Insights.svg": View_Insinghts,
  };
  return iconsMap[requestType] || ""; // Default to an empty string if no match
};

function StoreOverviewDoThings(props) {

  const [mainRequestType, setMainRequestType] = useState();
  const [currentPage, setCurrentPage] = useState(true);
  const [buildBrandFrame, setBuildBrandFrame] = useState(false);
  const [solveProblemFrame, setSolveProblemFrame] = useState(false);
  const [mainTypeId, setMainTypeId] = useState();
  const permitionsArray = [
    {
      id: 1,
      text: "Build Your Brand",
      value: props.UserPermission ? props.UserPermission.DoThings_BuildYourBrand : true
    },
    {
      id: 2,
      text: "Solve Problems",
      value: props.UserPermission ? props.UserPermission.DoThings_SolveProblems : false
    },
    {
      id: 3,
      text: "Launch Something New",
      value: props.UserPermission ? props.UserPermission.DoThings_LaunchSomethingNew : false
    },
    {
      id: 4,
      text: "View Insights",
      value: props.UserPermission ? props.UserPermission.DoThings_View_Insights : false
    }
  ]
  useEffect(() => {
    GetAllMainRequestTypesService(props.token).then((response) => {
      if (response) {
        console.log(response);

        // Filter response for items where value is true in permitions
        const filteredResponse = response.filter((item) => {
          const permission = permitionsArray.find(
            (perm) => perm.text == item.requestType && perm.value === true
          );
          return permission !== undefined;
        });

        setMainRequestType(filteredResponse); // Set the filtered response
        props.closeMaintype();

//         const data = response && response.filter(item => item.requestType !== 'Launch Something New');
//         if(props.accessLevel != 4)
//         {
//           setMainRequestType(response);
//         }
//         else{
//           setMainRequestType(data);
//         }
//         props.closeMaintype()

      }
    });
  }, [props.UserPermission]);

  const handleClick = (id, requestType) => {
    props.SetCurrentOption(requestType, 1)
    setMainTypeId(id)
    setSolveProblemFrame(true)
    setBuildBrandFrame(false)
    setCurrentPage(false);
  };
  const handleBrandClick = (id, requestType) => {
    props.SetCurrentOption(requestType, 1)
    setMainTypeId(id)
    setBuildBrandFrame(true)
    setSolveProblemFrame(false)
    setCurrentPage(false);
  }
  const goBack = () => {
    setMainTypeId('')
    setCurrentPage(true);
  }

  const handleMainTypes = (id, requestType) => {
    props.SetCurrentOption(requestType, 1);
    setMainTypeId(id);
    setCurrentPage(false);

    switch (requestType) {
      case 'Build Your Brand': {
          setBuildBrandFrame(true)
          setSolveProblemFrame(false)
          setCurrentPage(false)
          // setPermitionsValue(permitions.value)
      }
        break;
      case 'Solve Problems': {
        setSolveProblemFrame(true)
        setBuildBrandFrame(false)
      }
        break;
      case 'Launch Something New':
        props.handleProducts()
        break;
      default:
        console.log('object')
        setCurrentPage(true);

    }

  }
  const mainType = () => {
    return (
      <Container fluid >
        <Row className="justify-content-between">
          <Col md={12}>
            <h5 className="">What would you like to do?
              {/* <Button className="float-right btn_class">Get In Touch</Button> */}
            </h5>
          </Col>
          {mainRequestType
            ? mainRequestType.map((type) => (
              <Col md={6} className='mb-3 ' >
                {/* <a href="#" className="text-decoration-none " onClick={type.requestType == 'Build Your Brand' ?() => handleBrandClick(type.id,type.requestType):type.requestType == 'Launch Something New' ? ()=>props.handleProducts():type.requestType === 'Solve Problems'?() => handleClick(type.id,type.requestType): type.requestType === 'View Insights'? '':''}> */}
                <a href="#" className="text-decoration-none " onClick={() => handleMainTypes(type.id, type.requestType)}>
                  <Card className={`${type.requestType === 'Build Your Brand' ? 'doThingscardBuild1' : type.requestType == 'Solve Problems' ? 'doThingscardsolveprob1' : type.requestType == 'Launch Something New' ? 'doThingscardlaunch1' : type.requestType == 'View Insights' ? 'viewinsights1' : ''}`}>
                    <Row className=" mt-1 p-2">
                      <Col md={3} className={type.requestType === 'Build Your Brand' ? 'doThingscardBuild' : type.requestType == 'Solve Problems' ? 'doThingscardsolveprob' : type.requestType == 'Launch Something New' ? 'doThingscardlaunch' : type.requestType == 'View Insights' ? 'viewinsights' : ''}>

                        <img  className= {` rounded mx-auto d-block ${type.requestType == 'View Insights' ? ' mt-4 mb-2 ':'m-4'}`} src={type.requestType =='Solve Problems' ? Solveproblems_icon :type.requestType == 'Launch Something New' ? Launchsomthingnew_icon : type.requestType == 'Build Your Brand' ? Buildyourbrands_icon: type.requestType =='View Insights'?View_Insinghts:''} alt="not found"/>
                        {/* <img
                          className={`rounded mx-auto d-block ${type.requestType == 'View Insights' ? ' mt-4 mb-2 ' : 'm-4'}`}
                          src={`${CURRENT_API_URL + '/' + (type.IconImage)}`} /> */}
                      </Col>
                      <Col md={9} className=" " >
                        <h5 className=" ">{type.requestType}</h5>
                        <p>{type.helpText}</p>
                        <img src={ArrowIcon_icon} className={type.requestType == 'Solve Problems' ? "rounded float-right mt-3" : type.requestType == 'Launch Something New' ? "rounded float-right " : type.requestType == 'View Insights' ? "rounded float-right " : 'rounded float-right '} alt="not found" />
                      </Col>
                    </Row>
                    <Row >
                      <Col >
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
            ))
            : ""}
        </Row>
      </Container>
    )
  }

  return (
    <>
      {currentPage ? mainType() :
        buildBrandFrame ?
          <BuildYourBrandFrame1
            mainRequestTypeId={mainTypeId}
            goBack={goBack}
            SetCurrentOption={props.SetCurrentOption}
            closeMaintype={props.closeMaintype}
            closeProblemArea={props.closeProblemArea} />
          : solveProblemFrame ?
            <GetAllProblemAreas
              closeMaintype={props.closeMaintype}
              closeProblemArea={props.closeProblemArea}
              closeProblemAreaPathway={props.closeProblemAreaPathway}
              closeSubtype={props.closeSubtype}
              mainRequestTypeId={mainTypeId}
              goBack={goBack}
              SetCurrentOption={props.SetCurrentOption} /> : ''}
      {/* { currentPage ? <StoreOverviewDoThingsItems id={mainTypeId} goBack={goBack}/> : mainType() } */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
    storeOverview: state.accountOverview.store.storeOverview,
    UserPermission: state.accountOverview.store.StoreUserPermission,

  };
};
export default connect(mapStateToProps)(StoreOverviewDoThings);
