import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';

function GetAllTasksServices(token) {
    return axios.post(`${CURRENT_API_URL}/swinternalsystem/getAllTasks`, '',{
    headers: {
        'Authorization': token,
    }
    }).then(res => {
        notify.NotifySuccess("All tasks successfully fetched. Thank You!");       
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetched task. please try again later.");
        return false;
    });
}

export default GetAllTasksServices;