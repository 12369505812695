import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import ReactQuill from 'react-quill';


import { connect } from "react-redux";
import AddNewTaskServices from '../../../../../../Service/OPSServices/SWInternalServices/AddNewTaskServices';
import { Editor } from '../../../../../../Utils/Constants';
import TasksDatePicker from '../../../../../Components/Input/TasksDatePicker';


function CreateNewTask(props) {
  const [newTask, setNewTask] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([])
  const [text, setText] = useState('')
  const [taskStatus, setTaskStatus] = useState('');
  const [member, setMember] = useState();
  const [saveLoading, setSaveLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [selectedMembers, setSelectedMembers] = useState(props.loggedUserId);
  const [taskPriority,setTaskPriority]=useState('Normal')

  const [dateRange, setDateRange] = useState({
    startDate: new Date(), 
    endDate: new Date(),
  });

 
const formattedDate =dateRange.startDate ?dateRange.startDate.toLocaleDateString():''; // Format depends on the locale
const hours = dateRange.startDate.getHours().toString().padStart(2, '0');
const minutes = dateRange.startDate.getMinutes().toString().padStart(2, '0');
const seconds = dateRange.startDate.getSeconds().toString().padStart(2, '0');
const currentTime = `${hours}:${minutes}:${seconds}`;

// Combine the original date with the current time
const updatedDate = formattedDate.split(' ')[0] + ' ' + currentTime + formattedDate.slice(19);
const updateDate=updatedDate


const endformattedDate =dateRange.endDate ?dateRange.endDate.toLocaleDateString():''; // Format depends on the locale
console.log(formattedDate);
const endhours = dateRange.endDate.getHours().toString().padStart(2, '0');
const endminutes = dateRange.endDate.getMinutes().toString().padStart(2, '0');
const endseconds = dateRange.endDate.getSeconds().toString().padStart(2, '0');
const endcurrentTime = `${endhours}:${endminutes}:${endseconds}`;

// Combine the original date with the current time
const endupdatedDate = endformattedDate.split(' ')[0] + ' ' + endcurrentTime + endformattedDate.slice(19);

const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

// console.log(dateRange.startDate);

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
};
const handleSubmitSwTask = () => {
    const newErrors = {};

    if (!dateRange.startDate) {
      newErrors.startDate = 'Start date and End date are required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setSaveLoading(true)
    const formData = new FormData();
    formData.append("taskTitle", newTask);
    formData.append("status", 'new');
    formData.append("Comments", text);
    formData.append("startDate", updateDate);
    formData.append("endDate", endupdatedDate);
    formData.append("assignedTo", member?member:selectedMembers);
    formData.append('taskPriority',taskPriority)
    if (selectedFiles.length > 0) 
    {
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("taskAttachmentFile", selectedFiles[i]);
        }
    }
    else
    {
        formData.append("taskAttachmentFile",[]);
    }
    AddNewTaskServices(props.token, formData).then((res) => {
        if (res) {
            console.log(res)
            setText('')
            // setDateRange({
            //     startDate: null,
            //     endDate: null,
            // });
            setSelectedFiles([])
            setTaskStatus('')
            setNewTask('')
            setMember([])
            props.handleCloseCreateTaskPopup(res.tasklist)
        }
        setSaveLoading(false)
        // window.location.reload();

    })
  
}


const handleQuillChange = (value) => {
    setText(value);
};
const handleAssignedToChange = (e) => {
    const value = e.target.value;
    console.log(value)

    setSelectedMembers(value );
};
const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
};

const handlePriorityChange=(e)=>{
    setTaskPriority(e.target.value)
    console.log(e.target.value);
}
  return (
    <>
     <Card className='card-of-problemarea p-4'>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Label> <b>Task Title :<span  style={{ color: "red" }}
                                > * </span></b>  </Label>
                                <Input
                                    type='text'
                                    className='w-100'
                                    placeholder='Enter Text'
                                    onChange={(e) => setNewTask(e.target.value)}
                                />
                            </Col>
                            <Col  md={6}>
                                {/* <select
                                    id='assigned To'

                                    onChange={(e) => handleAssignedToChange(e)

                                    }
                                    className='form-control w-100'>
                                    <option id='' value=''>--Assigned To--</option>
                                    {props.teamMemberList && props.teamMemberList.map((member) => (
                                        <option
                                            key={member.employeeId}
                                            value={member.employeeId}
                                        // selected={member.employeeId}
                                        >{member.employeeName}</option>
                                    ))}
                                </select> */}
                                   
                            <Label><b>Assigned To :</b></Label>
                            <select
                                id="assignedTo"
                                onChange={(e) => handleAssignedToChange(e)}
                                className="form-control w-100"
                                value={selectedMembers }
                                // value={props.loggedUserId?props.loggedUserId:selectedMembers}
                            >
                                <option disabled selected value="">-- Select Assigned To --</option>
                                {props.teamMemberList &&
                                props.teamMemberList.map((member) => (
                                    <option key={member.employeeId} value={member.employeeId}>
                                    {member.employeeName}
                                    </option>
                                ))}
                            </select>
 

                            </Col>
                            <Col md={6}className='mt-3'>
                            <Label><b>Priority :</b></Label>
                            <select
                                id="priority"
                                name="priority"
                                onChange={handlePriorityChange}
                                value={taskPriority}
                                className="form-control"
                                >
                                <option disabled selected value="">--Select Priority--</option>
                                <option value="Critical">Critical</option>
                                <option value="Urgent">Urgent</option>
                                <option value="High">High</option>
                                <option value="Normal">Normal</option>
                                <option value="Low">Low</option>
                                </select>
                            </Col>
                            
                            <Col md={6} className='mt-3'>
                                <TasksDatePicker
                                    dateRange={dateRange}
                                    setDateRange={handleDateRangeChange}
                                />
                                {dateRange.startDate == null ? <span style={{ color: 'red' }}>{errors.startDate}</span>:''}
                            </Col>
                           

                            <Col className='mt-2' md={6}>

                                <p for="Attachment">
                                    <b>Upload Helpful Files : </b>
                                </p>
                                <label
                                    htmlFor="fileInput"
                                    className="custom-file-input-label text-center"
                                >
                                    <i className="fas fa-upload"></i>
                                    <br /> Choose File

                                    <input
                                        type="file"
                                        id="fileInput"
                                        name="ticketAttachment"
                                        className="custom-file-input"
                                        onChange={handleFileChange}
                                    />
                                </label>
                                </Col>
                                <Col md={6} className='mt-2'>
                                <span style={{ fontSize: "13px" }}><b>Attached File List :</b></span>
                                <ul style={{ color: "green", fontSize: "13px" }}>
                                    {selectedFiles
                                        ? selectedFiles.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <button
                                                    className="btn"
                                                    onClick={() => handleRemoveFile(file)}
                                                >
                                                    <FontAwesomeIcon
                                                        color="red"
                                                        size="15px"
                                                        icon={faWindowClose}
                                                    />
                                                </button>
                                            </li>
                                        ))
                                        : ""}
                                </ul>

                            </Col>
                            <Col md={12} className='mt-2 '>
                            <Label><b>Add Note For Task :<span style={{ color: 'red' }}> *</span></b></Label>
                                <ReactQuill
                                    className=""
                                    theme={'snow'}
                                    modules={Editor.modules}
                                    formats={Editor.formats}
                                    value={text}
                                    onChange={handleQuillChange}
                                    bounds={'.quill-prod'}
                                    placeholder='Note' />
                            </Col>
                            <Col md={12} className='d-flex justify-content-center mt-2'>
                                <Button
                                className='btn-background-color '
                                disabled = {!(newTask && text) || saveLoading}
                                    onClick={() => handleSubmitSwTask()}>
                                    {saveLoading ? 'Saving..' :'Save'}
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Card>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(CreateNewTask);
