import axios from 'axios';
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadStoreData } from "../../../../Redux/Actions/Actions";
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function StoreOverviewService(token, integerID, stringID) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_STORE_DATA_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/profile`, {
            integerID, stringID
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadStoreData(
                    integerID,
                    res.data.storeOverview,
                    res.data.productList,
                    res.data.newChildProducts,
                    res.data.newParentProducts,
                    res.data.orphanProducts,
                    res.data.claimedCampaigns,
                    res.data.unclaimedCampaigns,
                    res.data.weeklyReport,
                    res.data.ProductPerformanceReport,
                    res.data.TargetPerformanceReport,
                    res.data.StoreOverviewReport,
                    res.data.StoreOverviewPrimeReport,
                    res.data.WeeklySalesReport,
                    res.data.WeeklySearchTermReport,
                    res.data.SalesTrendsReport,
                    res.data.CampaignPerformanceReport,
                    res.data.StoreUserPermission,
                    
                    {
                        clicks: res.data.clickData,
                        conversions: res.data.conversionData,
                        impressions: res.data.impressionData,
                        sales: res.data.salesData,
                        spend: res.data.spendData,
                        unitsSold: res.data.unitsSoldData,
                    },
                    res.data.campaignUpdates,
                    res.data.creditPurchases,
                    ));
        })
            .catch(() => {
            dispatch({ type: actionType.LOAD_STORE_DATA_FAILED });
        })
    }
}

export default StoreOverviewService;
