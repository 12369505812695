import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
function DailyAmazonReportsMonitoringDataService(token) {

return axios.post(`${CURRENT_API_URL}/sellerPartner/reportsMonitoring`, '', {
    headers: {
        'Authorization': token,
    }
}).then(res => {
    notify.NotifySuccess("Your weekly recipient updated successfully!");
    return res.data;
}).catch(error => {
    console.log(error);
    notify.NotifyError("An error occurred while Adding Recipient. please try again later.");
    return false;
});
}
export default DailyAmazonReportsMonitoringDataService