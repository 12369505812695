import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const GridPagination = ({ pageSize, totalRecords, onPageChange, currentPageProp}) => {
  // Set initial page to 0 (default to the first page)
  const [currentPage, setCurrentPage] = useState(currentPageProp || 0);

  const totalPages = Math.ceil(totalRecords / pageSize);
  const startRecord = currentPage * pageSize + 1;
  const endRecord = Math.min((currentPage + 1) * pageSize, totalRecords);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onPageChange({ currentPage: newPage, pageSize });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onPageChange({ currentPage: newPage, pageSize });
    }
  };

  useEffect(() => {
    // If the parent component passes a new `currentPageProp`, we can update our local state
    if (currentPageProp !== undefined && currentPageProp !== currentPage) {
      setCurrentPage(currentPageProp);
    }
  }, [currentPageProp]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', fontWeight: 'normal', fontSize: '15px' }}>
      <FontAwesomeIcon
        icon={faChevronLeft}
        onClick={handlePreviousPage}
        title="Newer"
        style={{ 
          cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
          opacity: currentPage === 0 ? 0.5 : 1,
          fontSize: '16px',
        }}
      />

      {/* <span>{`Page ${currentPage +1} of ${totalPages}`}</span> */}
      <span>{totalPages === 0 ? 'Page 0 of 0' : `Page ${currentPage + 1} of ${totalPages}`}</span>

      <FontAwesomeIcon
        icon={faChevronRight}
        onClick={handleNextPage}
        title="Older"
        style={{ 
          cursor: totalPages === 0 || currentPage === totalPages - 1 ? 'not-allowed' : 'pointer',
          opacity: totalPages === 0 || currentPage === totalPages - 1 ? 0.5 : 1,
          fontSize: '16px',
        }}
      />
    </div>
  );
};

export default GridPagination;
