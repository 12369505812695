import axios from 'axios';
import notify from "../../../Notifications/ToastifyActions";
import { CURRENT_API_URL } from "../../../Utils/Constants";


function FetchAllPermissionByStoreUserService(token) {
    return axios.post(`${CURRENT_API_URL}/account/getAllPermissionByStoreUser`, '',{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(() => {
        notify.NotifyError('error occures while fetching permissions.');
        return null;
    })
}

export default FetchAllPermissionByStoreUserService;
