import React, {useState, useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import { connect } from 'react-redux';
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import {useHistory} from 'react-router-dom';

import LogoutService from "../../Service/EntryServices/LogoutService";
import urls from '../../Utils/URLs';
import actionType from '../../Redux/Actions/ActionTypes';
import CreateCampaignPage from "./Scenes/CreateCampaign/CreateCampaignPage";
import CreditsPage from "./Scenes/Credits/CreditsPage";
import DashboardHeader from "./Layout/Header/DashboardHeader";
import AccountOverviewPage from "./Scenes/AccountOverview/AccountOverviewPage";
import notify from "../../Notifications/ToastifyActions";
import LaunchpadPage from "./Scenes/Launchpad/LaunchpadPage";
import CreateProjectPage from './Scenes/CreateProject/CreateProjectPage';
import UserProfilePage from "./Scenes/UserProfile/UserProfilePage";
import SPAPIRedirectPage from "./Scenes/UserProfile/Scenes/Authorization/Components/SPAPIRedirectPage";
import ProtectedRoute from "../Components/Nav/ProtectedRoute";
import Can from "../Components/Can";
import TutorialModal from "./Components/Modals/Tutorial/TutorialModal";
import StoreListService from "../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";
import SubscriptionEmailService from "../../Service/PaymentServices/SubscriptionEmailService";
import AdvertisingAPIAuthorizationService
    from "../../Service/DashboardServices/UserProfileServices/AdvertisingAPIAuthorizationService";
import OperationsPage from "./Scenes/Operations/OperationsPage";
import UserSubscriptionLevelService from "../../Service/PaymentServices/UserSubscriptionLevelService";
import SellerPartnerDataPage from "./Scenes/Operations/Scenes/SellerPartnerData/SellerPartnerDataPage";
import AdvertisingDataPage from "./Scenes/Operations/Scenes/AdvertisingData/AdvertisingDataPage";
import RegistrationJourneyPage from "./Scenes/RegistrationJourney/RegistrationJourneyPage";
import AccountOverviewRelaunchPage from "./Scenes/AccountOverviewRelaunch/AccountOverviewPage";
import GetUserRegistrationJourneyDataService from "../../Service/DashboardServices/RegistrationJourneyServices/GetUserRegistrationJourneyDataService";

import TicketListPage from "./Scenes/TroubleTickets/TicketListPage";
// import StoreOverviewMainPage from "./Scenes/StoreOverview/StoreOverviewMainPage"
import StoreOverviewMainPage from './Scenes/StoreOverview_v1/StoreOverviewMainPage'
import { useParams } from 'react-router-dom';
import UserProfileViewPage from "./Scenes/UserProfileData/UserProfileViewPage";
import DashbordTicketListPage from "./Scenes/DashboardTicketList/DashbordTicketListPage";
import DashbordTicketListPage_v1 from "./Scenes/DashboardTicketList_v1/DashbordTicketListPage_v1";
import MyTasksMainPage from "./Scenes/My Tasks/MyTasksMainPage";
import NotificationsMainFile from "./Scenes/StoreOverview_v1/Scenes/Components/PartnerUpdateLog/NotificationsMainFile";

function RegistrationJourneyDashboard(props) {

    const [currentPage, setCurrentPage] = useState();
    const { profileId } = useParams();
    useEffect(() => {
        console.log('In RegistrationJourneyDashboard')
        console.log(localStorage.getItem('profileId'))
        if(localStorage.getItem('profileId') === null)
        {
          localStorage.setItem('profileId', '');
        }
        const pID = localStorage.getItem('profileId')
        console.log(profileId)
        console.log(pID)
        // console.log('Before StoreListService')
        props.dispatch(StoreListService(props.token));
        // console.log('After StoreListService')
      GetUserRegistrationJourneyDataService(props.token,pID).then(response => {
        if (response) {
            console.log('After StoreListService')
            console.log(response)
            if(response.RegJournneyStoreSetUpComplete)
            { console.log(response.storesList) 
                if(props.accessLevel ===0){
                    var pageUrl = '/dashboard'
                    setCurrentPage(pageUrl)
                }     
                else{
                    if(response.storesList.length > 0){
                        var pageUrl = '/store-overview-v1/myStores/' + response.storesList[0]['integerID']
                        setCurrentPage(pageUrl)
                      }
                      else{
                        setCurrentPage('/store-overview-v1/myStores/')
                      }
                    }
                    
                }          
             
            else
            {
                setCurrentPage('/registration-journey')
            }
        }
    })
  }, [])
    let history = useHistory();

    const logout = () => {
        localStorage.setItem('profileId', '');
        props.dispatch(LogoutService(props.token));
    }

    const [isTutorialOpen, setIsTutorialOpen] = useState(false);

    useEffect(() => {
        if (props.needsAdvertisingAPIAuth) {
            setIsTutorialOpen(true);
        }
    }, [props.needsAdvertisingAPIAuth])

    useEffect(() => {
        props.dispatch(UserSubscriptionLevelService(props.token));
    }, [])

    const handleTutorialConfirmation = () => {
        props.dispatch({type: actionType.CONFIRM_TUTORIAL});
        setIsTutorialOpen(false);
        props.dispatch(StoreListService(props.token));
        history.push(urls.PRICING_OPTIONS_URL);
    }

    const handleTutorialSubscriptionEmail = async (email) => {
        let response = await props.dispatch(SubscriptionEmailService(props.token, email));
        if (response) {
            setIsTutorialOpen(false);
        }
    }

    const handleAdvertisingAuth = () => {
        props.dispatch(AdvertisingAPIAuthorizationService(
            props.token,
            props.user._profile.id,
            props.user._token.accessToken,
            props.user._token.refreshToken,
            props.user._token.expiresAt,
        ));
    }

    return (
        // <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer">
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <div
                    className={cx(
                        "app-container app-theme-white",
                        { "fixed-header": true },
                        { "fixed-sidebar": true },
                        // { "fixed-sidebar": enableFixedSidebar || width < 1250 }
                        { "fixed-footer": true },
                        { "closed-sidebar": props.enableClosedSidebar || width < 1250 },
                        {
                            // "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                        },
                        { "sidebar-mobile-open": props.enableMobileMenu },
                        // { "body-tabs-shadow-btn": enablePageTabsAlt }
                    )}>
                    <DashboardHeader logout={logout}/>
                    { (props.status && props.status === 'LOGOUT_FAILED') &&
                    notify.NotifyError(props.error)
                    }
                    <Switch>

                        <Route path={urls.BASE_USER_PROFILE_URL}>
                            <UserProfilePage />
                        </Route>

                        <Route role={props.accessLevel}
                            perform={"ops:sellerPartnerData"}
                            exact={false} path={urls.OPS_SELLER_PARTNER_DATA_URL}>
                            <SellerPartnerDataPage />
                        </Route>
                        <Route role={props.accessLevel}
                            perform={"ops:advertisingData"}
                            exact={false} path={urls.OPS_ADVERTISING_DATA_URL}>
                            <AdvertisingDataPage/>
                        </Route>

                        {/*{props.newUser && <Redirect to={urls.USER_PROFILE_STORE_AUTH_URL}/>}*/}

                        {/*{*/}
                        {/*    props.needsAdvertisingAPIAuth &&*/}
                        {/*    <Redirect to={urls.USER_PROFILE_AUTH_URL} />*/}
                        {/*}*/}
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"stores:view"}
                            exact={false}
                            path={`${urls.BASE_REGISTRATION_JOURNEY_URL}/:profileId?`}
                        >
                            <RegistrationJourneyPage />
                        </ProtectedRoute>
                        
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"profile:userProfile"}
                            exact={false}
                            path={`${urls.BASE_USER_PROFILE_DATA_URL}`}
                        >
                            <UserProfileViewPage />
                        </ProtectedRoute>
                        {/* <ProtectedRoute
                            role={props.accessLevel}
                            perform={"ticket:view"}
                            exact={false}
                            path={`${urls.BASE_TROUBLE_TICKET_URL}/:mainTypeId/:subTypeId?`}
                        >
                            <TicketListPage />
                        </ProtectedRoute> */}
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"store:view"}
                            exact={false}
                            path={`${urls.BASE_STORE_OVERVIEW_URL}`}
                        >
                            <StoreOverviewMainPage/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"store:view"}
                            exact={false}
                            path={`${urls.BASE_DASHBOARD_URL}`}
                        >
                            <MyTasksMainPage/>
                        </ProtectedRoute>
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"store:notifications"}
                            exact={false}
                            path={`${urls.BASE_NOTIFICATIONS_URL}`}
                        >
                            <NotificationsMainFile/>
                        </ProtectedRoute>
                        {/* <ProtectedRoute
                            role={props.accessLevel}
                            perform={"stores:view"}
                            exact={false}
                            path={urls.BASE_ACCOUNT_OVERVIEW_RELAUNCH_URL}
                        >
                            <AccountOverviewRelaunchPage />
                        </ProtectedRoute> */}
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"stores:view"}
                            exact={false}
                            path={urls.BASE_ACCOUNT_OVERVIEW_URL}
                        >
                            <AccountOverviewPage />
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"campaigns:create"}
                            exact={false}
                            path={urls.BASE_CREATE_CAMPAIGN_URL}
                        >
                            <CreateCampaignPage />
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"campaigns:launch"}
                            exact={false}
                            path={urls.BASE_LAUNCHPAD_URL}
                        >
                            <LaunchpadPage/>
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"pricing:view"}
                            exact={false}
                            path={urls.BASE_PRICING_URL}
                        >
                            <CreditsPage/>
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"project:view"}
                            exact={false}
                            path={urls.BASE_PROJECTS_URL}
                        >
                            <CreateProjectPage />
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform="ops:view"
                            exact={false}
                            path={urls.BASE_OPS_URL}
                        >
                            <OperationsPage />
                        </ProtectedRoute>
                        {/* <ProtectedRoute
                            role={props.accessLevel}
                            perform="home:simple"
                            exact={false}
                            path={urls.BASE_TROUBLE_TICKET_URL}
                        >
                            <DashbordTicketListPage />
                        </ProtectedRoute> */}
                        <ProtectedRoute
                            role={props.accessLevel}
                            perform="home:simple"
                            exact={false}
                            path={urls.BASE_TROUBLE_TICKET_URL}
                        >
                            <DashbordTicketListPage_v1 />
                        </ProtectedRoute>

                        <Route
                            path={urls.STORE_AUTH_REDIRECT_URL}
                        >
                            <SPAPIRedirectPage/>
                        </Route>
                        {/*Default landing page after login*/}
                        <Can
                            role={props.accessLevel}
                            perform={"stores:view"}
                            yes={() => <Route render={() => <Redirect to={{pathname: currentPage}} />} />}
                            no={() => <Route render={() => <Redirect to={{pathname: urls.BASE_PROJECTS_URL}} />} />}
                        />
                    </Switch>

                    <TutorialModal
                        isOpen={isTutorialOpen}
                        toggle={() => setIsTutorialOpen(!isTutorialOpen)}
                        handleConfirmation={handleTutorialConfirmation}
                        handleEmailSubmit={handleTutorialSubscriptionEmail}
                        isAuth={props.isAuth}
                        needsAdvertisingAPIAuth={props.needsAdvertisingAPIAuth}
                        handleAuth={handleAdvertisingAuth}
                    />
                </div>
            )}
        />
    );
}

const mapStateToProps = state => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        newUser: state.loggedUser.newUser,
        needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
        user: state.LWA.user,
        isAuth: state.LWA.isAuth,
        enableMobileMenu: state.theme.enableMobileMenu,
        enableClosedSidebar: state.theme.enableClosedSidebar,
    }
}

export default connect(mapStateToProps)(RegistrationJourneyDashboard);
