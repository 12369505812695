import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import ReactQuill from 'react-quill';

import { connect } from "react-redux";

import ReactTable from 'react-table';
import CustomModal from '../../../../Components/Modals/CustomModal';
import GetAllTasksServices from '../../../../../Service/OPSServices/SWInternalServices/GetAllTasksServices';
import AddNewTaskServices from '../../../../../Service/OPSServices/SWInternalServices/AddNewTaskServices';
import DateRangePicker from '../../../../Components/Input/DateRangePicker';
import { Editor } from '../../../../../Utils/Constants';
function SwTeamCreatetask(props) {
    const [taskList, setTaskList] = useState()
    const [selectedFiles, setSelectedFiles] = useState([])
    const [newTaskPopUp, setNewTaskPopUp] = useState(false)
    const [newTask, setNewTask] = useState('');
    const [taskStatus, setTaskSatus] = useState('');
    const [text, setText] = useState('')
    const [teamMemberList, setTeamMemberList] = useState('')
    const [member, setMember] = useState('')
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    console.log(dateRange)
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        GetAllTasksServices(props.token).then((responce) => {
            if (responce) {
                console.log('responce')
                console.log(responce)
                setTaskList(responce.tasklist)
                setTeamMemberList(responce.teamMembers)
            }
        })

    }, [])
    const handleRemoveFile = (file) => {
        const updatedFiles = selectedFiles.filter((f) => f !== file);
        setSelectedFiles(updatedFiles);
    };
    const handleSubmitSwTask = () => {

        const formData = new FormData();
        formData.append("taskTitle", newTask);
        formData.append("status", taskStatus);
        formData.append("Comments", text);
        formData.append("startDate", dateRange.startDate);
        formData.append("endDate", dateRange.endDate);
        formData.append("assignedTo", member);
        if (selectedFiles.length > 0) 
        {
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("taskAttachmentFile", selectedFiles[i]);
            }
        }
        else
        {
            formData.append("taskAttachmentFile",[]);
        }
        AddNewTaskServices(props.token, formData).then((res) => {
            if (res) {
                console.log(res)
                setText('')
                setDateRange({
                    startDate: null,
                    endDate: null,
                });
                setTaskList(res.allTasks.tasklist)
                setSelectedFiles([])
                setTaskSatus('')
                setNewTask('')
                setMember('')
                setNewTaskPopUp(false)
            }
        })
      
    }

    const handleChangeStatus = (e) => {
        setTaskSatus(e.target.value)

    }
    const handleQuillChange = (value) => {
        setText(value);
    };
    const handleAssignedToChange = (e) => {
        setMember(e.target.value)
    }
    const handleFileChange = (e) => {
        // setFlag(1)
        const files = Array.from(e.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };
    const columns = [

        {
            Header: 'Id',
            accessor: 'SWTeamTaskId',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),
        },
        {
            Header: 'Task Title',
            accessor: 'taskTitle', 
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),
        },
        {
            Header: 'Status',
            accessor: 'status', Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),
        },
        {
            Header: 'Assigned To',
            accessor: 'assignedName',
            Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),

        },
        {
            Header: 'Start Date',
            accessor: 'plannedStartDate', Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),
        },
        {
            Header: 'End Date',
            accessor: 'plannedEndDate', Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),
        },
        // {
        //     Header: 'CreatedAt',
        //     accessor: 'createdAt'
        // },
        // {
        //     Header: 'CreatedBy',
        //     accessor: 'createdBy'
        // },
        {
            Header: 'CompletedAt',
            accessor: 'completedAt', Cell: (cellInfo) => (
                <div className="d-flex justify-content-center w-100">
                  <p className="m-0">{cellInfo.value}</p>
                </div>
              ),
        },
    ]
    const handleSearchInputChange=(e)=>{
        setSearchInput(e.target.value)
    }
    const flattenedFileList = Array.isArray(taskList) ? taskList.filter((row) => {
        const taskTitle = (row.taskTitle || '').toLowerCase();
        const status = (row.status || '').toLowerCase();
        const assignedName = (row.assignedName || '').toLowerCase();
        const SWTeamTaskId = (row.SWTeamTaskId || '').toString().toLowerCase();
        return SWTeamTaskId.includes(searchInput.toString().toLowerCase()) || taskTitle.includes(searchInput.toLowerCase()) || status.includes(searchInput.toLowerCase())  || assignedName.includes(searchInput.toLowerCase())
    }) : [];
    
    return (
        <>
            <Card className='card-of-problemarea mt-3 p-4'>
                <Col md={12} className='d-flex justify-content-between mb-3'>
                    <Input type='text'
                        className='w-25 '
                        placeholder='Search Text'
                        onChange={(e)=>handleSearchInputChange(e)}
                    />
                    <Button
                    className='btn-background-color' 
                    onClick={() => setNewTaskPopUp(true)}>
                        New Task
                    </Button>
                </Col>
                <Col md={12}>
                    <ReactTable
                        data={flattenedFileList}
                        columns={columns}
                        className="-fixed -highlight -striped"
                        getTheadProps={() => {
                            return {
                                style: {
                                    overflowY: "hidden",
                                    background: "#B1FA63",
                                    color: "#243837",
                                },
                            };
                        }}
                        defaultPageSize={5}
                        noDataText={"No Data Found."}
                    />
                </Col>


            </Card>


            <CustomModal
                header={"Task"}
                isOpen={newTaskPopUp}
                toggle={() => setNewTaskPopUp(!newTaskPopUp)}
                size="lg"
                className='card-of-problemarea bgColor '
            >
                <Card className='card-of-problemarea p-4'>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Label> <b>Task Title <span  style={{ color: "red" }}
                                >
                                  *
                                </span></b>  </Label>
                                <Input
                                    type='text'
                                    className='w-100'
                                    placeholder='Enter Text'
                                    onChange={(e) => setNewTask(e.target.value)}
                                />
                            </Col>
                            <Col md={6}>
                                <Label><b> Status :</b></Label>
                                <select className='form-control w-100'
                                    id='newTask'
                                    name='newTask'
                                    value={taskStatus}
                                    onChange={handleChangeStatus}>
                                    <option id='' value=''>--Select Status--</option>
                                    <option id='' value='New'>New</option>
                                    <option id='' value='In progress'>In progress</option>
                                    <option id='' value='Completed'>Completed</option>
                                    {/* {
                                        taskList && taskList.map((task) => (
                                            <option id=''>{task.status}</option>
                                        ))
                                    } */}

                                </select>
                            </Col>
                            <Col md={12} className='mt-3'>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                            </Col>
                            <Col className='mt-2' md={6}>
                                <Label><b> Assigned To :</b></Label>
                                <select
                                    id='assigned To'

                                    onChange={(e) => handleAssignedToChange(e)

                                    }
                                    className='form-control w-100'>
                                    <option id='' value=''>--Assigned To--</option>
                                    {teamMemberList && teamMemberList.map((member) => (
                                        <option
                                            key={member.employeeId}
                                            value={member.employeeId}
                                        // selected={member.employeeId}
                                        >{member.employeeName}</option>
                                    ))}
                                </select>
                            </Col>

                            <Col className='mt-2' md={6}>

                                <p for="Attachment">
                                    <b>Helpful screenshots or images : </b>
                                </p>
                                <label
                                    htmlFor="fileInput"
                                    className="custom-file-input-label text-center"
                                >
                                    <i className="fas fa-upload"></i>
                                    <br /> Choose File

                                    <input
                                        type="file"
                                        id="fileInput"
                                        name="ticketAttachment"
                                        className="custom-file-input"
                                        onChange={handleFileChange}
                                    />
                                </label>
                                <span style={{ fontSize: "13px" }}><b>Attached file List</b></span>
                                <ul style={{ color: "green", fontSize: "13px" }}>
                                    {selectedFiles
                                        ? selectedFiles.map((file, index) => (
                                            <li key={index}>
                                                {file.name}
                                                <button
                                                    className="btn"
                                                    onClick={() => handleRemoveFile(file)}
                                                >
                                                    <FontAwesomeIcon
                                                        color="red"
                                                        size="15px"
                                                        icon={faWindowClose}
                                                    />
                                                </button>
                                            </li>
                                        ))
                                        : ""}
                                </ul>

                            </Col>
                            <Col md={12} className='mt-2'>
                                <ReactQuill
                                    className=""
                                    theme={'snow'}
                                    modules={Editor.modules}
                                    formats={Editor.formats}
                                    value={text}
                                    onChange={handleQuillChange}
                                    bounds={'.quill-prod'}
                                    placeholder='Note' />
                            </Col>
                            <Col md={12} className='d-flex justify-content-center mt-2'>
                                <Button
                                className='btn-background-color '
                                disabled = {!newTask}
                                    onClick={() => handleSubmitSwTask()}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Card>
            </CustomModal>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,

    };
};

export default connect(mapStateToProps)(SwTeamCreatetask);