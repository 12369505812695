import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import { connect } from "react-redux";
import ViewTaskHistory from './SubComponentsSwTeamTask/ViewTaskHistory';
import CreateNewTask from './SubComponentsSwTeamTask/CreateNewTask';
import TaskCreatedByMe from './SubComponentsSwTeamTask/TaskCreatedByMe';
import TaskAssingedToMe from './SubComponentsSwTeamTask/TaskAssingedToMe';
import CustomModal from '../../../../Components/Modals/CustomModal';
import AddHistoryService from '../../../../../Service/OPSServices/SWInternalServices/AddHistoryService';
import GridPagination from '../../../../Components/Pagination/GridPagination';

function SWTeamTask(props) {
  const [openTaskTitle, setOpenTaskTitle] = useState(false)
  const [taskName, setTaskName] = useState();
  const [viewTaskPopup, setViewTaskPopup] = useState(false)
  const [taskData, setTaskData] = useState()
  const [createTaskPopup, setCreateTaskPopup] = useState(false)
  const [statusFilter, setStatusFilter] = useState('All')
  const [searchInput, setSearchInput] = useState("");
  const [assignedLoading, setAssingedLoading] = useState(false)
  const [seletctedCurrentPage,setSeletctedCurrentPage] = useState(3)
  const [seletctedCurrentPg,setSeletctedCurrentPg] = useState(0)
  const [assignedToFilter,setAssignedToFilter]=useState()
  const [createdByFilter,setCreatedByFilter]=useState()
  const [todaysTaskFilter,setTodaysTaskFilter]=useState()

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  }
  const handleAssignedToTaskList = ( page ,tasks, loggedUserId) => {
    setSeletctedCurrentPage(page)
    setAssignedToFilter(loggedUserId);  // Set filter when clicking "Assigned To"
    setCreatedByFilter(null) 
    setTodaysTaskFilter(null)
    setSeletctedCurrentPg(0)
  };

  const handleCreatedByTaskList = (page ,tasks, loggedUserId) => {
    setSeletctedCurrentPage(page)
    setCreatedByFilter(loggedUserId); 
    setAssignedToFilter(null)
    setTodaysTaskFilter(null)
    setSeletctedCurrentPg(0)
  };
  const handleTodaysTaskList = ( page ,tasks, loggedUserId) => {
    console.log(loggedUserId)
    setSeletctedCurrentPage(page)
    setTodaysTaskFilter(loggedUserId);  // Set filter when clicking "Assigned To"
    setCreatedByFilter(null) 
    setAssignedToFilter(null)
    setSeletctedCurrentPg(0)
  };

 
  const filteredTasks = props.taskList &&props.taskList.filter((task) => {
    const matchesStatus = statusFilter === "All" || task.status === statusFilter;
    const matchesSearch = (task.taskTitle|| '').toLowerCase().includes(searchInput.toLowerCase());
    const priority = (task.priority || '').toLowerCase().includes(searchInput.toLowerCase());
    const assignedToMatch = (task.assignedName||'').toLowerCase().includes(searchInput.toLowerCase());
    const SWTeamTaskIdMatch = (task.SWTeamTaskId || '').toString().includes(searchInput.toLowerCase());
    const statusMatch = (task.status||'').toLowerCase().includes(searchInput.toLowerCase());
    
    // Filter based on "Assigned To" and "Created By"
    const matchesAssignedToFilter = assignedToFilter ? task.assignedTo === assignedToFilter : true;
    const matchesCreatedByFilter = createdByFilter ? task.createdById === createdByFilter : true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Set the time to midnight (00:00:00)

    // // Optimized logic to match today's task filter
    // const taskDate = new Date(task.plannedStartDate);
    // taskDate.setHours(0, 0, 0, 0); // Only need to set the time of task once

    // const matchesTodaysTaskFilter = todaysTaskFilter ? taskDate.getTime() === today.getTime() : true;
    // const matchesTodaysTaskFilter = todaysTaskFilter ? ['New','In Progress', 'Overdue'].includes(task.status) && task.plannedStartDate<= today : true;
    const matchesTodaysTaskFilter = todaysTaskFilter
    ? ['New', 'In Progress', 'Overdue'].includes(task.status) && new Date(task.plannedStartDate).setHours(0, 0, 0, 0) <= today.getTime(): true;

    return (
      matchesStatus &&
      (matchesSearch || statusMatch || assignedToMatch || SWTeamTaskIdMatch ||priority) &&
      matchesAssignedToFilter && matchesTodaysTaskFilter&&
      matchesCreatedByFilter
    );
  });

  const handleNewTask = () => {
    setCreateTaskPopup(true)
  }
  const handleViewTaskTitle = (task) => {
    setTaskName(task)
    setOpenTaskTitle(true)
  }
  const handleVivewTask = (taskData) => {
    console.log(taskData)
    setViewTaskPopup(true)
    setTaskData(taskData)
  }
  const handleCloseCreateTaskPopup = (tasklist) => {
    props.setTaskList(tasklist);
    setCreateTaskPopup(false)
    setViewTaskPopup(false)
  }
  const handleSubmitHistory = (e, task, flag) => {

    const formData = new FormData();
    if (flag == 'assignedTo') {
      formData.append("assignedTo", e.target.value);
      formData.append("note", 'assignedTo');
    }
    else {
      formData.append("assignedTo", task.assignedTo);
    }
    if (flag == 'status') {
      formData.append("status", e.target.value);
      formData.append("note", 'status');
    }
    else {
      formData.append("status", task.status);
    }
    formData.append("taskId", task.SWTeamTaskId);
    formData.append("flag", 1);

    console.log(formData);
    AddHistoryService(props.token, formData).then((responce) => {
      if (responce) {
        console.log(formData)
        console.log(responce)
        props.setTaskList(responce.tasklist)
        props.setTeamMemberList(responce.teamMembers)
        // handleCreatedByTaskList(responce.tasklist, responce.loggedUserId)
        // handleAssignedToTaskList(responce.tasklist, responce.loggedUserId)
      }
      setAssingedLoading()
    })
  }

  const pageSize = 15;  

  const totalRecords = filteredTasks.length;
  // const startIndex = seletctedCurrentPg * pageSize;
  // const endIndex = startIndex + pageSize;
  const startIndex = seletctedCurrentPg * pageSize;
  const endIndex = startIndex + pageSize;
  const tasksToDisplay = filteredTasks.slice(startIndex, endIndex);

  const handleBackFunction = (value) => {
    setCreatedByFilter(null) 
    setAssignedToFilter(null)
    setSeletctedCurrentPage(value)
    setTodaysTaskFilter(null)
    setSeletctedCurrentPg(0)


  }
  const handlePageChange = (pageSize) => {
    setSeletctedCurrentPg(pageSize.currentPage); // Update the page when the user clicks on pagination
  };
  return (
    <>
      <Card className="card-min-height-workflows mt-3 p-3 card-of-problemarea">
        <Row>
        <Col md={12} className='mt-2'>
        <div className='d-flex'>
       
          <h6
          className={`mr-3 ${seletctedCurrentPage === 3?'selected-my-tasks':'not-seletcted-tasks'}`}
          onClick={() => handleBackFunction(3)}>
           <b> All Tasks</b>
         
        </h6>
          <h6
            className={seletctedCurrentPage === 2?'selected-my-tasks':'not-seletcted-tasks'}
            onClick={() => handleCreatedByTaskList(2,props.taskList,props.loggedUserId)}>
           <b> Created By Me</b>
          </h6>
          <h6
            className={` ml-3 ${seletctedCurrentPage === 1?'selected-my-tasks ':'not-seletcted-tasks'}`}
            onClick={() => handleAssignedToTaskList(1,props.taskList,props.loggedUserId)}>
            <b>Assigned To Me</b>
          </h6>
          <h6
            className={` ml-3 ${seletctedCurrentPage === 4?'selected-my-tasks ':'not-seletcted-tasks'}`}
            onClick={() => handleTodaysTaskList(4,props.taskList,props.loggedUserId)}>
            <b>Active Tasks</b>
          </h6>


          {/* <Button
            className='btn-background-color ml-auto'
            onClick={() => handleNewTask(true)}>
            New Task
          </Button> */}
          </div>
        </Col>
        <Row className='p-3 '>
          <Col md={12}>
          <Row>
          <Col md={8} className="d-flex">
            <Input type='text'
              className='w-25 '
              placeholder='Search Text'
              onChange={(e) => handleSearchInputChange(e)}
            />
            </Col>
            <Col md={4} className={`d-flex justify-content-end pagination-item`}>
            <GridPagination                 
              pageSize={pageSize}
              totalRecords={totalRecords}
              currentPageProp={seletctedCurrentPg}
              onPageChange={handlePageChange}
            />
             </Col>
            </Row>
            
            <label className='mr-3 mt-2'>
              <input type='radio'
                name='status'
                checked={statusFilter === "All"}
                value={statusFilter}
                //  checked={status.status}
                onChange={() => {setStatusFilter('All');handlePageChange({ currentPage: 0 })}} />
              &nbsp;
              <b>All</b>
            </label>
            {props.taskStatus && props.taskStatus.map((status) => (
              <label className='mr-3'>
                <input type='radio'
                  name='status'
                  checked={statusFilter === status.status}
                  value={status.status}
                  //  checked={status.status}
                  onChange={() => {setStatusFilter(status.status);handlePageChange({ currentPage: 0 })}} />
                &nbsp;
                <b>{status.status}</b>
              </label>
            ))}

          </Col>
          <Col md={12} >
          <div className="tasklist-scrolle">
            <table className="table custom-scroll-table">
              <thead className='custom-scroll-table thead'>
                <tr>
                  <th scope="col" className='text-center'>View</th>
                  <th scope="col" className='text-center'>Title</th>
                  <th scope="col" className='text-center'>Status</th>
                  <th scope="col" className='text-center'>Priority</th>
                  <th scope="col" className='text-center'>Assigned To</th>
                  <th scope="col" >Planned Start Date</th>
                  <th scope="col"  >Planned End Date</th>
                  <th scope="col"  >Created By</th>
                  <th scope="col"  >Completed At</th>
                </tr>
              </thead>
              <tbody >
                {tasksToDisplay &&
                  tasksToDisplay.map((task) => (
                    <tr key={task.SWTeamTaskId} style={{ backgroundColor: task.rowColor ? task.rowColor : '' }}>
                      
                      <td className="cursor-pointer">
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" onClick={() => handleVivewTask(task)}>
                            {task.SWTeamTaskId}
                          </p>
                          </div>
                      </td>
                      <td className="cursor-pointer">
                        <div className="d-flex">
                          
                          <p
                            className="m-0 comment-paragraph"
                            onClick={() => handleViewTaskTitle(task.taskTitle)}
                          >
                            {task.taskTitle}
                          </p>
                        </div>
                      </td>
                      <td>
                        <>
                        {/* {task.status ==="Completed"? */}
                        <p className='mb-0 text-center'>{task.status}</p>
                        {/* :
                        <>
                          {satusLoading === task.SWTeamTaskId ? (
                          <p className="mb-0" style={{ color: "#81e94b" }}>
                            Updating..
                          </p>
                        ) : (
                          <select
                            name="status"
                            id="status"
                            className="form-control w-100 text-center"
                            onChange={(e) => {
                              handleSubmitHistory(e, task, "status");
                              setSatusLoading(task.SWTeamTaskId);
                            }}
                          >
                            
                            <option value="">-- Select status --</option>
                            {taskStatus &&
                              taskStatus.map((status) => (
                                <option
                                  value={status.status}
                                  selected={status.status === task.status}
                                >
                                  {status.status}
                                </option>
                              ))}
                          </select>
                        )}
                        </>
                        } */}
                        </>
                        
                      </td>
                      <td>
                        <p className='text-center mb-0'>{task.priority}</p>
                      </td>
                      <td>
                        <>
                        {task.status ==="Completed"?
                        <p className='mb-0 text-center'>{task.assignedName}</p>:
                        <>
                          {assignedLoading === task.SWTeamTaskId ? (
                          <p className="mb-0" style={{ color: "#81e94b" }}>
                            Updating..
                          </p>
                        ) : (
                          <select
                            className="form-control w-100"
                            name="assignedto"
                            id="assignedto"
                            onChange={(e) => {
                              handleSubmitHistory(e, task, "assignedTo");
                              setAssingedLoading(task.SWTeamTaskId);
                            }}
                          >
                            <option disabled selected value="">-- Assigned to --</option>
                            {props.teamMemberList &&
                              props.teamMemberList.map((member) => (
                                <option
                                  key={member.employeeId}
                                  value={member.employeeId}
                                  selected={member.employeeId === task.assignedTo}
                                >
                                  {member.employeeName}
                                </option>
                              ))}
                          </select>
                        )}
                        </>
                        }
                        </>
                        
                      </td>
                      <td>{task.plannedStartDate}</td>
                      <td>{task.plannedEndDate}</td>
                      <td>{task.createdBy}</td>
                      <td>{task.completedAt}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            </div>
            </Col>
        </Row>
        </Row>
      </Card>
      
      <CustomModal
        header={"Task Title"}
        isOpen={openTaskTitle}
        toggle={() => setOpenTaskTitle(!openTaskTitle)}
        size={"md"}
        className='my-task-custom-module'
      >
        <Card className='card-of-problemarea p-2'>
          <Col md={12} >
            <p className=' mt-1'>{taskName}</p>
          </Col>

        </Card>
      </CustomModal>
      <CustomModal
        header={<>
          <h6>View Task / <b>Task Id :</b>&nbsp;{taskData && taskData.SWTeamTaskId}</h6>
        </>}
        isOpen={viewTaskPopup}
        toggle={() => setViewTaskPopup(!viewTaskPopup)}
        size={"xl"}
        className='my-task-custom-module'
      >
        <>
          <ViewTaskHistory
          userData={props.userData}
          loggedUserId={props.loggedUserId}
            teamMemberList={props.teamMemberList}
            statusList={props.taskStatus}
            taskList={props.taskList}
            taskName={taskName}
            viewTaskData={taskData}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          />
        </>

      </CustomModal>
      <CustomModal
        header={"Task Title"}
        isOpen={createTaskPopup}
        toggle={() => setCreateTaskPopup(!createTaskPopup)}
        size={"lg"}
        className='my-task-custom-module'
      >
        <Card className='card-of-problemarea'>
          <CreateNewTask
            teamMemberList={props.teamMemberList}
            taskList={props.taskList}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          />
        </Card>
      </CustomModal>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(SWTeamTask);