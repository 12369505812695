import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import ReactTable from "react-table";

import DailyAmazonReportsMonitoringDataService from '../../../../../../../Service/OPSServices/StoreHealthServices/DailyAmazonReportsMonitoringDataService';
import { Col, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';


function DailyAmazonReportsMonitoringData(props) {
const [monitoringJobs,setMonitoringJobs]=useState('')
const[loading,setLoading]=useState(false)

    useEffect(() => {
      setLoading(true)
        console.log('sayali')

        // const data = { integerId: props.integerId };
        DailyAmazonReportsMonitoringDataService(props.token).then((res) => {
            if(res){
                console.log('sayali')
                console.log(res)
                setMonitoringJobs(res[0])
                console.log(res[0].Ex_asin_sales_count)
                setLoading(false)

            }
                
          
        });
    }, []);
    console.log(monitoringJobs)


  


  return (
    <>
    <Row>
    <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: '#f1f4f6',
              opacity: 1,
            }),
            content: (base) => ({
              ...base,
              color: '#000',
            }),
          }}
          spinner={<Loader active type="ball-pulse" />}
          text='Loading your Daily Reports Monitoring...'
      >
      <Col md={12} className='mb-4'>
      <h4 className='mb-4'>Daily Reports Monitoring</h4>
      <Row>
     <Col md={6}>
     <p><strong>Total Managed Stores :</strong> {monitoringJobs.distinct_count}</p>
     <p><strong>Advertising Latest Update :</strong> {monitoringJobs.max_date_advertisingAPI}</p>
     <p><strong>Store Sales Latest Update:</strong> {monitoringJobs.max_date_storeSales}</p>
     </Col>
     <Col md={6}>
     <p><strong>Total Asins of Managed Stores :</strong> {monitoringJobs.total_asins}</p>
     <p><strong>Asin Sales Latest Update :</strong> {monitoringJobs.max_date_AsinSales}</p>
         

     </Col>
     </Row>
       
    
    </Col>
      <Col md={12}>
      <table className="table custom-scroll-table">
              <thead className='custom-scroll-table thead' styles={{background:'#28a745'}}>
                <tr>
                  <th scope="col" className='text-center'>NAME</th>
                  <th scope="col" className='text-center'>EXPECTED</th>
                  <th scope="col" className='text-center'>ACTUAL</th>
                  
                </tr>
              </thead>
              <tbody >
               
                    <tr >
                      <td>
                        <p className='mb-0'>Total Requested Adv Report</p>
                      </td>
                      
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                        {console.log(monitoringJobs)}
                        {console.log(monitoringJobs.Ex_Asin_Lavel_rows)}
                            {monitoringJobs.Ex_requested_adv_report}
                          </p>
                          </div>
                      </td>
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                      {monitoringJobs.requested_adv_report}

                          </p>
                          </div>
                      </td> 
                      </tr>
                      <tr >
                      <td>
                        <p className='mb-0'>Successfully Requested Report</p>
                      </td>
                      
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.Ex_successfully_requested_report}
                          </p>
                          </div>
                      </td>
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.successfully_requested_report}
                          </p>
                          </div>
                      </td> 
                      </tr>
                      <tr >
                      <td>
                        <p className='mb-0'>Error Requested Reports</p>
                      </td>
                      
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.ex_error_requested_reports}
                          </p>
                          </div>
                      </td>
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.error_requested_reports}
                          </p>
                          </div>
                      </td> 
                      </tr>
                      <tr >
                      <td>
                        <p className='mb-0'>Store Level Sales Rows Count</p>
                      </td>
                      
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.Ex_store_sales_count}
                          </p>
                          </div>
                      </td>
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.store_sales_count}
                          </p>
                          </div>
                      </td> 
                      </tr>
                      <tr >
                      <td>
                        <p className='mb-0'>Asin Level Sales Rows Count</p>
                      </td>
                      
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.Ex_asin_sales_count}
                          </p>
                          </div>
                      </td>
                      <td >
                      <div className="d-flex justify-content-center">
                      <p className="mr-2 mb-0" >
                            {monitoringJobs.asin_sales_count}
                          </p>
                          </div>
                      </td> 
                      </tr>
                      </tbody>
                      </table>

     
      </Col>
      </LoadingOverlay>
    </Row>
   
    </>
  )
}
const mapStateToProps = (state) => {
    return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      error: state.loggedUser.error,
      adProfiles: state.accountOverview.landing.adProfiles,
    };
  };

export default connect(mapStateToProps)(DailyAmazonReportsMonitoringData);