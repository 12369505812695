import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import { connect } from "react-redux";
import SelectableProductWidget from "../../../../../Components/Widgets/Products/SelectableProductWidget";
import SelectableProductWidgetList from "../../../../../Components/Widgets/Products/SelectableProductWidgetList";
import SelectableParentProductWidget from "../../../../../Components/Widgets/Products/SelectableParentProductWidget";
import { FormatProductWidgetData } from "../../../../../Components/Widgets/Products/WidgetDataFunctions/FormatProductWidgetData";
import WidgetListCard from "../../../../../../Components/Widgets/WidgetListCard";
import StarRating from "../../../Components/Rating/StarRating";
import Vector_Arrow from "./../../../../../../../designUtils/Logo/Vector_Arrow.svg";
import Arrow_DownWords from "./../../../../../../../designUtils/Logo/Arrow_DownWords.svg";
import Stop_icon from "../../../../../../../designUtils/img/pause.svg";
import Play_icon from "../../../../../../../designUtils/img/play.svg";
import Alert_icon from "../../../../../../../designUtils/img/Alert.svg";
import Ticket_icon from "../../../../../../../designUtils/img/Ticket_icon.svg"
import Info from "../../../../../../../designUtils/img/info.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import GetCatalogueProductDetailsService from "../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/GetCatalogueProductDetailsService";
import CatalogueItemDetails from "../../../../../Components/Widgets/Products/CatalogueItemDetails";
import QuickLaunchCampaignService from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/QuickLaunchCampaignService";
import StoreOverviewService from "../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";
import ProductsAlertTicketCreate from "../../../../TroubleTickets/Components/ProductTickets/ProductsAlertTicketCreate";
import CampaignLaunchTutorialModal from "../Modals/CampaignLaunchTutorialModal";
import QuickLaunchCampaignModal from "../Modals/QuickLaunchCampaignModal";
import UpdateCampaignBudgetModal from "../Modals/UpdateCampaignBudgetModal";
import CampaignOverviewService from "../../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/CampaignOverviewService";
import Group from '../../../../../../../assets/img/StoreOverview_Img/Group.png'
function ProductList(props) {
  const [budgetIsOpen, setBudgetIsOpen] = useState(false);


  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChaild, setIsOpenChaild] = useState(false);
  const [isOpenOPChaild, setIsOpenOPChaild] = useState(false);
  const [parentToggleId, setParentToggleId] = useState();
  const [childToggleId, setChildToggleId] = useState();

  //   right side card
  const [isTicketListOpen, setIsTicketListOpen] = useState(false);
  const [asinForTicketList, setAsinForTicketList] = useState();
  const [catlogProductDetails, setCatlogProductDetails] = useState();
  const [isOpenCatalogueItem, setIsOpenCatalogueItem] = useState();
  const [isManagementOpen, setIsManagementOpen] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState("paused");
  const [isCampaignTutorialOpen, setIsCampaignTutorialOpen] = useState(false);
  const [hideCampaignTutorial, setHideCampaignTutorial] = useState(
    JSON.parse(localStorage.getItem("hide-campaign-tutorial")) || false
  );
  const [isCampaignOpen, setIsCampaignOpen] = useState(false);
  // const [asinArray, setAsinArray] = useState([]);
  // const [checked, setChecked] = useState(false);
  useEffect(()=>{
      setIsOpenChaild(false)
  },[props.storeOverview.integerID])
  const [childProductData, setChildProductData] = useState({
    asin: "",
    price: "",
    brand: "",
    productBudget: "",
    link: "",
    sku: "",
    skus: [],
    IntegerId: "",
    simpliworksManagementState: "",
    salesRanking: ''
  });
  const [newCampaignDetails, setNewCampaignDetails] = useState({
    productASIN: "",
    productSKU: "",
    skuList: [],
    dailyBudget: 50,
  });
  const managementStates = {
    INACTIVE: "unManaged",
    ACTIVE: "managed",
    PAUSED: "paused",
  };
  console.log(props.storeOverview.integerID);
  console.log(props.storeIntegerID);
  const toggleState = () => {
    if (
      childProductData.simpliworksManagementState === "paused" ||
      childProductData.simpliworksManagementState === "unManaged"
    ) {
      setChildProductData({
        ...childProductData,
        simpliworksManagementState: "managed",
      });
      props.updateProductManagement(childProductData.asin, "resume");
    } else {
      setChildProductData({
        ...childProductData,
        simpliworksManagementState: "paused",
      });
      props.updateProductManagement(childProductData.asin, "pause");
    }
  };
  const renderTicketList = (asin) => {
    setAsinForTicketList(asin);
    setIsTicketListOpen(true);
  };

  const handleProductDetails = async (asin, integerId) => {
    let response = await GetCatalogueProductDetailsService(
      props.token,
      asin,
      integerId
    );

    if (response) {
      console.log("response");
      setCatlogProductDetails(<CatalogueItemDetails data={response} />);
      setIsOpenCatalogueItem(true);
    }
  };
  function handleClickViewAnalytic() {
    alert("Development for this functionality is in progress.");
  }
  const toggleCampaignModal = (asin, skuList) => {
    console.log(asin)
    console.log(skuList)
    if (!hideCampaignTutorial) {
      setIsCampaignTutorialOpen(!isCampaignTutorialOpen);
    }
    setIsCampaignOpen(!isCampaignOpen);
    setNewCampaignDetails((prevState) => ({
      productSKU: skuList ? skuList[0] : "",
      productASIN: asin,
      skuList: skuList,
      dailyBudget: prevState.dailyBudget,
    }));
  };

  useEffect(() => {
    localStorage.setItem(
      "hide-campaign-tutorial",
      JSON.stringify(hideCampaignTutorial)
    );
  }, [hideCampaignTutorial]);

  const handleQuickLaunchCampaign = async (asin, sku, dailyBudget) => {
    setIsManagementOpen(true);
    let response = await QuickLaunchCampaignService(
      props.token,
      props.storeIntegerID,
      asin,
      sku,
      dailyBudget
    );
    if (response) {
      props.dispatch(StoreOverviewService(
        props.token,
        props.storeIntegerID,
        props.storeAccountID
      )
      );
    }
    return response;
  };
  const handleCreateAndViewTicketClose = (response) => {
    setIsTicketListOpen(false);
  };
  //  end right side card
  const childToggle = (

    asin,
    price,
    brand,
    productBudget,
    link,
    sku,
    IntegerId,
    skus,
    simpliworksManagementState,
    salesRanking

  ) => {
    console.log(salesRanking)
    if (isOpenChaild) {
      setIsOpenChaild(false);
      setIsOpenOPChaild(false)
      setChildToggleId(0);
    } else {
      setChildProductData({
        asin: asin,
        price: price,
        brand: brand,
        productBudget: productBudget,
        link: link,
        sku: sku,
        skus: skus,
        IntegerId: IntegerId,
        simpliworksManagementState: simpliworksManagementState,
        salesRanking: salesRanking
      });
      setIsOpenChaild(true);
      setIsOpenOPChaild(true)
      setChildToggleId(asin);
      props.setIsOpenChaild(true)
    }
  };
  const parentToggle = (asin) => {

    if (isOpen && props.isOpen) {
      setIsOpen(false);
      setIsOpenChaild(false);
      setParentToggleId(0);
      props.setIsOpen(false);
    } else {
      setIsOpen(true);
      setIsOpenOPChaild(false);
      setIsOpenChaild(false);
      setParentToggleId(asin);
      props.setIsOpen(true);
    }
  };

  let groupedWidgets = props.parentProducts.map((parent) => {
    const { productTitle, asin, itemImage, isParentProduct } = parent;

    if (!isParentProduct) return FormatProductWidgetData(parent); // orphan product, not parent

    const details = {
      productVariation: asin,
    };

    let childVariations = props.childProducts.filter(
      (child) => child.parentProduct === parent.asin
    );

    return {
      productName: productTitle,
      productImage: itemImage,
      details: details,
      children: childVariations,
      isParentProduct: isParentProduct,
      numManagedChildren: childVariations.reduce(
        (count, childProduct) => count + childProduct.isManaged,
        0
      ),
    };
  });

  groupedWidgets = groupedWidgets.filter(
    (parent) => !parent.isParentProduct || parent.children.length !== 0
  );
  const loadCampaignInfo = (name, campaignID) => {
    props.dispatch(CampaignOverviewService(props.token, name, campaignID));
  }
  const updateBudget = () => {
    // console.log("first surya")
    // loadCampaignInfo(name, campaignID);
    setBudgetIsOpen(!budgetIsOpen);
    return <UpdateCampaignBudgetModal isOpen={true} toggle={() => setBudgetIsOpen(!budgetIsOpen)} />
  }
  const renderGroupedProductWidgets = (parent, key) => (
    <Row>

      {parent.isParentProduct ? (
        <>
          <Col className="col-md-12">
            <div className="d-flex">

              {props.UserPermission ? props.UserPermission.Products_ManageUnmanage && <div className="mt-5">

                <input
                  className="mr-2 mt-3"
                  value={parent.details.productVariation}
                  type="checkbox"
                  name={parent.details.productVariation}
                  onChange={(e) => props.handleChange(e)}
                  checked={props.asinArray && props.asinArray.includes(parent.details.productVariation) ? true : false}
                />
              </div> : ""}

              <Card
                className={
                  isOpen && parentToggleId == parent.details.productVariation && props.isOpen
                    ? "selected-parent-card mb-2 parent-card card-product product-card-width"
                    : "mb-2 parent-card card-product product-card-width"
                }
              >
                <CardBody>
                  <Row>
                    <Col md={2} className="p-2">

                      <img src={parent.productImage} width={85} height={85} />
                    </Col>
                    <Col md={9} className="pl-3 pr-0">
                      <h6>
                        <b>{parent.productName}</b>
                      </h6>
                      <p className="mb-0 pb-0" style={{ color: '#6c757d' }}>
                        <b>ASIN: </b>{parent.details.productVariation}
                      </p>
                      <p className="mb-0 pb-0">
                        {parent.numManagedChildren} variations under management.
                      </p>
                    </Col>
                    <Col md={1} className="pr-0">
                      <button
                        className="toggle-icon"
                        onClick={() => parentToggle(parent.details.productVariation)}
                      >
                        <img
                          src={
                            isOpen && parentToggleId == parent.details.productVariation && props.isOpen
                              ? Arrow_DownWords
                              : Vector_Arrow
                          }
                          alt="not found"
                        />
                      </button>
                    </Col>
                    {isOpen && parentToggleId == parent.details.productVariation && props.isOpen ? (
                      <Row className="m-3">
                        {parent.children.map((childProduct) => (
                          <Col
                            md={12}
                            className="p-1"
                            onClick={() => {
                              childToggle(
                                childProduct.asin,
                                childProduct.itemPrice,
                                childProduct.brand,
                                childProduct.productBudget,
                                childProduct.link,
                                childProduct.sku,
                                childProduct.IntegerId,
                                childProduct.skus,
                                childProduct.simpliworksManagementState,
                                childProduct.salesRanking
                              );
                            }
                            }
                          >
                            <Card
                              className={
                                // (isOpenOPChaild && (childToggleId == parent.details.ASIN))

                                isOpenChaild && childToggleId == childProduct.asin
                                  ? "selected-child-card child-card card-product"
                                  : "child-card card-product"
                              }
                            >
                              {console.log("first")}
                              {console.log(childToggleId)}
                              {console.log(childProduct.asin)}
                              <CardBody>
                                <Row>

                                  <Col md={2} className="p-2">
                                    <img
                                      src={childProduct.itemImage}
                                      width={85}
                                      height={85}
                                    />
                                  </Col>
                                  <Col md={10} className="pl-3 pt-1 pb-0">
                                    <p className="pb-0 mb-0">
                                      {childProduct.productTitle}
                                    </p>
                                    <h6 className="">
                                      <StarRating
                                        initialRating={childProduct.itemRating}
                                        readonly
                                        stop={5}
                                      />
                                      <span>
                                        {parseFloat(
                                          childProduct.itemRating
                                        ).toFixed(1)}
                                      </span>{" "}
                                      &nbsp;
                                      <span style={{ fontSize: "16px" }}>
                                        {childProduct.ratingVotes} Reviews
                                      </span>
                                    </h6>
                                    {childProduct.simpliworksManagementState ==
                                      "managed" ? (
                                      <>
                                        <i
                                          className="pe-7s-check"
                                          style={{
                                            fontSize: "16px",
                                            color: "green",
                                            fontWeight: "bold",
                                          }}
                                        ></i>
                                        <span className="mt-1 text-brand-blue-dark">
                                          &nbsp; Managed by Simpliworks
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>

        </>
      ) : (
        <Col className="col-md-12 product-card-width">
          <div className="d-flex">

            {props.UserPermission ? props.UserPermission.Products_ManageUnmanage && <div className="mt-5">
              <input
                className="mr-2 mt-3"
                value={parent.details.ASIN}
                type="checkbox"
                name={parent.details.ASIN}
                onChange={(e) => props.handleChange(e)}
                checked={props.asinArray && props.asinArray.includes(parent.details.ASIN) ? true : false}
              />
            </div> : ""}

            <Card
              className={
                (isOpenOPChaild && (childToggleId == parent.details.ASIN))
                  ? "selected-child-card mb-2 parent-card card-product product-card-width"
                  : "mb-2 parent-card card-product product-card-width"
              }
            >
              <CardBody>

                <Row
                  onClick={() => {
                    childToggle(
                      parent.details.ASIN,
                      parent.details.price,
                      parent.details.brand,
                      parent.details.budget,
                      parent.details.link,
                      parent.sku,
                      parent.IntegerId,
                      parent.skuList,
                      parent.managementStatus,
                      parent.details.salesRanking
                    );
                    setIsOpen(false);
                  }
                  }
                >

                  <Col md={2} className="p-2">

                    <img src={parent.productImage} width={85} height={85} />
                  </Col>
                  <Col md={9} className="pl-3 pr-0">
                    <h6>
                      <b>{parent.productTitle}</b>
                    </h6>
                    <h6 className="">
                      <StarRating
                        initialRating={parent.productRating}
                        readonly
                        stop={5}
                      />
                      <span>{parseFloat(parent.productRating).toFixed(1)}</span>{" "}
                      &nbsp;
                      <span style={{ fontSize: "16px" }}>
                        {parent.ratingVotes} Reviews
                      </span>
                    </h6>
                  </Col>
                  <Col md={1} className="pr-0"></Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      )}
    </Row>
  );

  return (
    <>
      <WidgetListCard
        widgets={groupedWidgets}
        renderWidgetListItem={renderGroupedProductWidgets}
        card={props.card}
        setClass={props.setClass}
      />

      {isOpenChaild && (props.storeOverview.integerID) && props.isOpenChaild ? (

        <Col
          md={4}
          className="p-0 rightside-card ml-2"
          style={{ position: "sticky", top: "4rem", right: '0' }}
        >
          <Card className={childProductData ? "m-3" : "m-3"}>
            <CardBody className="p-0 detail-card">
              <div className="p-4">
                <div className="d-flex justify-content-around mb-3">
                  {props.UserPermission?props.UserPermission.Products_ManageUnmanage && <button className="btn-product" onClick={toggleState}>

                    {childProductData.simpliworksManagementState === "unManaged" ?
                      <img
                        src={Play_icon}
                        alt="Start"
                        title="Start Managment"
                        width={30}
                        height={30}
                      /> : childProductData.simpliworksManagementState === "paused" ? (
                        <img
                          src={Play_icon}
                          alt="Stop"
                          title="Start Managment"
                          width={30}
                          height={30}
                        />
                      ) : (
                        <img
                          src={Stop_icon}
                          alt="Play"
                          title="Pause Management"
                          width={30}
                          height={30}
                        />
                      )}
                    <br></br>
                    Manage
                  </button>:''}

                  {props.UserPermission?props.UserPermission.Products_ProductsCreateTicketPopUp && <button

                    className="btn-product"
                    onClick={() => renderTicketList(childProductData.asin)}
                  >
                    <img src={Ticket_icon} width={30} height={30} title="Create ticket" />
                    <br></br>
                    Tickets
                  </button>:''}
                  <button
                    onClick={() =>
                      handleProductDetails(
                        childProductData.asin,
                        childProductData.IntegerId
                      )
                    }
                    className="btn-product"
                  >
                    <img src={Info} width={30} height={30} title="Details" />
                    <br></br>
                    &nbsp; &nbsp; Info  &nbsp; &nbsp;
                  </button>
                </div>

                <div className="padding-2">
                  <p>
                    <b>ASIN:</b> {childProductData.asin}
                  </p>
                  <p>
                    <b>Brand:</b> {childProductData.brand ? childProductData.brand : 'Unavailable'}
                  </p>
                  <p>
                    <b>Budget:</b>  {childProductData.productBudget != 'None' ? "$" + childProductData.productBudget + '/day' : '$0.00/day'} <a onClick={() => updateBudget()} href='#'>&#40;<u>Edit</u>&#41;</a>
                  </p>
                  <p>
                    <b>Price:</b> {childProductData.price.includes('$') ? childProductData.price : "$" + childProductData.price}
                  </p>
                  <p className="mb-0">
                    <b>Best Sellers Rank :</b>
                  </p>
                  {childProductData.salesRanking ?
                    childProductData.salesRanking.split(',').map((part, index) => (
                      <React.Fragment key={index}>
                        {part.trim()}<br />
                      </React.Fragment>
                    ))
                    : ''}
                </div>
                <p color="light" className="text-center">
                  <span
                    className={` ${childProductData.simpliworksManagementState === managementStates.ACTIVE
                        ? "text-brand-blue-dark"
                        : childProductData.simpliworksManagementState === managementStates.PAUSED
                          ? "text-warning"
                          : ""
                      }`}
                  >
                    <i
                      className={` ${childProductData.simpliworksManagementState ===
                          managementStates.ACTIVE
                          ? "pe-7s-check mt-2"
                          : childProductData.simpliworksManagementState ===
                            managementStates.PAUSED
                            ? "pe-7s-attention text-warning"
                            : "pe-7s-close mt-2 text-danger"
                        }`}
                      style={{
                        fontSize: "14",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    ></i>
                    {childProductData.simpliworksManagementState ===
                      managementStates.ACTIVE
                      ? " Managed by Simpliworks"
                      : childProductData.simpliworksManagementState ===
                        managementStates.PAUSED
                        ? " Paused"
                        : "Unmanaged"}
                  </span>
                </p>
                <p className="text-center">
                  <a href={childProductData.link} target="_blank">
                    Visit on Amazon
                  </a>
                </p>
              </div>
              <div className="d-flex justify-content-around pb-3">
                <button
                  className="green-btn pl-3 pr-3"
                  onClick={handleClickViewAnalytic}
                >
                  View Analytics
                </button>
                <button
                  className="green-btn pl-3 pr-3"
                  onClick={handleClickViewAnalytic}
                >
                  Optimize Listing
                </button>
              </div>
              {props.UserPermission? props.UserPermission.Products_OneClickCampaign && <div className="text-center campaign-div">
                  <button
                    class="campaign-btn"
                    role="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleCampaignModal(
                        childProductData.asin,
                        childProductData.skus
                      );
                    }}
                  >
                    1-Click Campaign
                  </button>
                </div>:''}

                {/* ) : (
                    ""
                  )} */}
              </CardBody>
              </Card>
        </Col>
      ) : (
        ""
      )}
      <CustomModal
        header={<><h5><b>Create Ticket For :</b>  <img
          width={props.storeOverview.logo  ? '180' : 50}
          height={50}
          src={props.storeOverview.logo? props.storeOverview.logo : Group} /> {props.storeOverview.name}</h5></>}
        isOpen={isTicketListOpen}
        toggle={() => setIsTicketListOpen(!isTicketListOpen)}
        size={"xl"}
        className="bgColor"
      >
        <ProductsAlertTicketCreate
          asinForTicketList={asinForTicketList}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          childProductData={childProductData}
        />
      </CustomModal>
      <CustomModal
        header={"1-Click Campaign"}
        isOpen={isCampaignOpen}
        toggle={() => setIsCampaignOpen(!isCampaignOpen)}
        size={"sm"}
        className="bgColor"
      >
        <QuickLaunchCampaignModal
          isCampaignOpen={isCampaignOpen}
          setIsCampaignOpen={setIsCampaignOpen}
          newCampaignDetails={newCampaignDetails}
          setNewCampaignDetails={setNewCampaignDetails}
          isManagementOpen={isManagementOpen}
          setIsManagementOpen={setIsManagementOpen}
          handleQuickLaunchCampaign={handleQuickLaunchCampaign}
          integerId={props.storeIntegerID}
          token={props.token}
        />
      </CustomModal>
      <CustomModal
        header={"1"}
        isOpen={isCampaignTutorialOpen}
        toggle={() => setIsCampaignTutorialOpen(!isCampaignTutorialOpen)}
        size={"sm"}
        className=""
      >
        <CampaignLaunchTutorialModal
          isOpen={isCampaignTutorialOpen}
          setIsOpen={setIsCampaignTutorialOpen}
          hideTutorial={hideCampaignTutorial}
          setHideTutorial={setHideCampaignTutorial}
        />
      </CustomModal>
      <CustomModal
        header={"Product Details"}
        isOpen={isOpenCatalogueItem}
        className=""
        toggle={() => setIsOpenCatalogueItem(!isOpenCatalogueItem)}
        size={"lg"}
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsOpenCatalogueItem(!isOpenCatalogueItem)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label> {catlogProductDetails}</Label>
      </CustomModal>
    </>
  );
}
// export default ProductList;
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    storeOverview: state.accountOverview.store.storeOverview,
    // childProducts: state.accountOverview.store.childProducts,
    adProfiles: state.accountOverview.landing.adProfiles,
    // Power BI Reports
    UserPermission: state.accountOverview.store.StoreUserPermission,
    weeklyReport: state.accountOverview.store.weeklyReport,
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
    StoreOverviewReport: state.accountOverview.store.StoreOverviewReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    SalesTrendsReport: state.accountOverview.store.SalesTrendsReport,
    WeeklySearchTermReport: state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport: state.accountOverview.store.CampaignPerformanceReport,

    storeLoading: state.accountOverview.store.loading,
    status: state.accountOverview.store.status,
    campaignData: state.accountOverview.product.campaignData,
    productCampaigns: state.accountOverview.product.productCampaigns,

  };
};
export default connect(mapStateToProps)(ProductList);
