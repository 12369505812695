import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table'; // Deprecated, consider upgrading to React-Table v7 or later
import { Row, Col } from 'reactstrap'
import { connect } from "react-redux";

import GetAgenciesDataService from '../../../../../../../Service/OPSServices/StoreHealthServices/GetAgenciesDataService';
import UpdateActiveAgenciesService from '../../../../../../../Service/OPSServices/StoreHealthServices/UpdateActiveAgenciesService';

function Agencies(props) {
  const [agenciesData, setAgenciesData] = useState([]);
  const [searchText, setSearchText] = useState('');

  // Example to get data (uncomment and use if needed)
  useEffect(() => {
    GetAgenciesDataService(props.token).then((res) => {
      if (res) {
        console.log(res);
        setAgenciesData(res.agenciesData);
      }
    });
  }, []); 
  
  const handleUpdateAgenciesActive=(agencies)=>{
        let data={
            id:agencies.id,
        }
UpdateActiveAgenciesService(props.token, data).then((res)=>{
    if(res){
        console.log(res)
        setAgenciesData(res.agenciesData); 
    }
})
  }

  const agenciesColumn = [
    {
      Header: 'Integer ID',
      accessor: 'integerId', // assuming the key is 'integerId'
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="mb-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Store Name',
      accessor: 'storeName', // assuming the key is 'storeName'
    },
    {
      Header: 'Role',
      accessor: 'accessLevel', // assuming the key is 'accessLevel'
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="mb-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
        Header: 'Email',
        accessor: 'email', // assuming the key is 'accessLevel'
        Cell: (cellInfo) => (
          <div className="d-flex justify-content-center w-100">
            <p className="mb-0 comment-paragraph">{cellInfo.value}</p>
          </div>
        ),
      },
    {
      Header: 'Full Name',
      accessor: 'fullName', // assuming the key is 'firstName'
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-start  w-100">
          <p className="mb-0 comment-paragraph">{cellInfo.value}</p>
        </div>
      ),
    },
    {
        Header: 'Is Active',
        accessor: 'isActive', // assuming the key is 'firstName'
        Cell: (cellInfo) => (
          <div className="d-flex justify-content-center w-100">
            <input
                style={{cursor:'pointer'}}
                type='checkbox'
                checked={cellInfo.value ? true : false}
                onClick={() => handleUpdateAgenciesActive(cellInfo.original)}
            />

          </div>
        ),
      },
    // {
    //   Header: 'Role',
    //   accessor: 'role', // assuming the key is 'lastName'
    //   Cell: (cellInfo) => (
    //     <div
    //       className="d-flex cursor-pointer justify-content-center w-100"
    //     >
    //       <p className="m-0">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
  ];

  const filterAgenciesData = agenciesData &&agenciesData.filter((row) => {
    const storeName = (row.storeName || '').toLowerCase();
    const integerId = (row.integerId || '').toString().toLowerCase();
    const accessLevel = (row.accessLevel || '').toString().toLowerCase();
    const fullName = (row.fullName || '').toString().toLowerCase();
    const email = (row.email || '').toString().toLowerCase();
    return (
      integerId.includes(searchText.toLowerCase()) ||
      storeName.includes(searchText.toLowerCase())||
      accessLevel.includes(searchText.toLowerCase())||
      fullName.includes(searchText.toLowerCase())||
      email.includes(searchText.toLowerCase())
    );
  });

  return (
    <>
      <Row>
        <Col md={12}>
          <input
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="form-control mb-3 w-25"
          />
          <ReactTable
            data={filterAgenciesData}
            columns={agenciesColumn}
            className="-fixed -highlight -striped"
            getTheadProps={() => {
                return {
                    style: {
                        overflowY: "hidden",
                        background: "#B1FA63",
                        // color: "#243837",
                    },
                };
            }}
            defaultPageSize={10}
            noDataText="No Data Found."
          />
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state) => {
    return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      error: state.loggedUser.error,
      adProfiles: state.accountOverview.landing.adProfiles,
    };
  };

export default connect(mapStateToProps) (Agencies);
