import React, { useEffect } from 'react';
import { Col, Form, FormGroup, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Ensure to import styles for datepicker

/**
 * Date range input component that provides input fields for a start and end date using a calendar selector.
 * Dates are stored as JS Date objects in ISO format.
 * @param dateRange - state object containing startDate and endDate fields
 * @param setDateRange - callback function that modifies the date range state object
 * @param props - contains any other properties passed to this component
 */
function TasksDatePicker({ dateRange, setDateRange, ...props }) {

    useEffect(() => {
        // Set the default start date to today's date if startDate is not set yet
        if (!dateRange.startDate) {
            setDateRange(prevState => ({
                ...prevState,
                startDate: new Date(),  // Default start date to today's date
            }));
        }
    }, [dateRange, setDateRange]);

    const handleDateChange = ({ startDate, endDate }) => {
        startDate = startDate || dateRange.startDate;
        endDate = endDate || dateRange.endDate;

        if (startDate > endDate) {
            endDate = startDate;
        }

        setDateRange({ startDate: startDate, endDate: endDate });
    }

    const handleChangeStart = startDate => handleDateChange({ startDate });

    const handleChangeEnd = endDate => handleDateChange({ endDate });

    return (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="startDate" className="mr-sm-2"><b>Start Date :</b></Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>
                            </InputGroupAddon>
                            <DatePicker
                                selected={dateRange.startDate}
                                selectsStart
                                className="form-control"
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={handleChangeStart}
                                minDate={new Date()} // Disable dates before today
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="endDate" className="mr-sm-2"><b>End Date :</b></Label>
                        <DatePicker
                            selected={dateRange.endDate}
                            selectsEnd
                            className="form-control"
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={handleChangeEnd}
                            minDate={dateRange.startDate || new Date()} // Ensure endDate is not before startDate
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

export default TasksDatePicker;
