import axios from 'axios';

import { CURRENT_API_URL } from '../../../../../Utils/Constants'; 
import notify from '../../../../../Notifications/ToastifyActions'


function UpdateFavMessageService(token, data ) {
    return axios.post(`${CURRENT_API_URL}/notifications/favNotifications`, data,{
        headers: {
            'Authorization': token,
        }
        }).then(res => {
                 
            return res.data;        
        }).catch(error => {
            console.log(error);
            notify.NotifyError("An error occurred while updating the favorite notification. Please try again later");
            return false;
        });
    }

export default UpdateFavMessageService;