import React, {Fragment} from "react";
import { connect } from 'react-redux';
import { Col } from "reactstrap";

import LoginForm from "./Components/LoginForm";
import notify from "../../../../Notifications/ToastifyActions";


function LoginPage(props) {

    return (
        <Fragment>
            <div className="h-100 loginPage bg-animation">
                <div className="d-flex bg-img h-100 justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box">
                        {/*<div className="app-logo mx-auto mb-3"/>*/}

                        <div className="modal-dialog w-100 mx-auto">
                            <LoginForm />
                        </div>
                        <div className="text-center text-white opacity-8 mt-3">
                            Copyright &copy; Simpliworks Incorporated {new Date().getFullYear()}
                        </div>
                    </Col>
                </div>
            </div>
            { (props.status && props.status === 'LOGIN_FAILED') &&
                notify.NotifyError(props.error)
            }
        </Fragment>
    );
}

const mapStateToProps = state => {
   return {
       status: state.loggedUser.status,
       error: state.loggedUser.error,
   }
}

export default connect(mapStateToProps)(LoginPage);
