import axios from 'axios';

import { CURRENT_API_URL } from '../../../../../Utils/Constants'; 
import notify from '../../../../../Notifications/ToastifyActions'


function FetchAllNotifications(token,  ) {
    return axios.post(`${CURRENT_API_URL}/notifications/getNotifications`, '',{
        headers: {
            'Authorization': token,
        }
        }).then(res => {
            // notify.NotifySuccess("Your notification fetched successfully. Thank You!");       
            return res.data;        
        }).catch(error => {
            console.log(error);
            notify.NotifyError("An error occurred while notification fetching . please try again later.");
            return false;
        });
    }

export default FetchAllNotifications;