import React, {useEffect,useRef, useState } from 'react'
import { Chart, PieController, ArcElement, Legend, Tooltip} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(PieController, ChartDataLabels, ArcElement, Legend, Tooltip);

function PieChart(props) {
    const chartRef = useRef(null); 
  const chartInstance = useRef(null);

  
  const chartData = {
    labels: props.Status?props.Status:[],
    datasets: [
      {
        data: props.count?props.count:[],
        backgroundColor: props.color?props.color:[],
        borderWidth: 0.5,
        borderColor: 'black',
      },
    ],
  };

  useEffect(() => {
    if (chartData) {
      const pieChartContext = chartRef.current.getContext('2d');

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(pieChartContext, {
        type: 'pie',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                boxWidth: 10, 
                boxHeight: 10, 
                font: {
                  size: 11, 
                },
              },
            },
            datalabels: {
              color: 'black',
              font: {
                size: 12,
                weight: 'bold',
              },
              formatter: (value) => (value > 0 ? value : ''), 
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
  <div style={{height: '220px', position: 'relative' }}>
      <canvas ref={chartRef} />
  </div>
  );
}

export default PieChart

