import React, { Fragment, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Input,
    Label,
    Row,
} from "reactstrap";
import { connect } from "react-redux";
import Search from "./../../../../../../assets/img/StoreOverview_Img/search.svg";
import Campaign from "./../../../../../../assets/img/StoreOverview_Img/campaign.svg";
import Target from "./../../../../../../assets/img/StoreOverview_Img/target.svg";
import Weekly from "./../../../../../../assets/img/StoreOverview_Img/weekly.svg";
import Product from "./../../../../../../assets/img/StoreOverview_Img/product.svg";
import Campaigns from "../../../../../../designUtils/Storeoverview/Reports/Campaigns.svg"
import SearchTerms from "../../../../../../designUtils/Storeoverview/Reports/SearchTerms.svg"
import Targeting from "../../../../../../designUtils/Storeoverview/Reports/Targeting.svg"
import WeeklySales from "../../../../../../designUtils/Storeoverview/Reports/WeeklySales.svg"
import ProductPerformance from "../../../../../../designUtils/Storeoverview/Reports/ProductPerformance.svg"

import CustomModal from "../../../../../Components/Modals/CustomModal";
import PowerbiEmbed from "../../Components/PowerBI/PowerbiEmbed";
function StoreOverviewReports(props) {
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [store, setStore] = useState(props.adProfiles[0]);
    const [reportName, setReportName] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [report, setReport] = useState("report4");

    var report1, report2, report3, report4, report5;

    if (store) {
        switch (report) {
            case "report1":
                var table = "AdvCampaignReportsSummary";
                var column = "IntegerId";
                report1 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.CampaignPerformanceReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report2":
                var table = "SearchTerm_Report";
                var column = "ProfileId";
                console.log(props.WeeklySearchTermReport);
                report2 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.WeeklySearchTermReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report3":
                var table = "Merge1";
                var column = "AdvTargetingSummary.ProfileId";
                report3 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.TargetPerformanceReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report4":
                var table = "newamazonadvertiserprofile";
                var column = "integerID";
                report4 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.WeeklySalesReport}
                        profileId={props.profileId}
                    />
                );
                break;
            case "report5":
                // var table = "Merge2_SPAPI_Asin_sku+Merge1_Summary";
                // var column = "ProfileId";
                var table = "newproductonamazon";
                var column = "IntegerId";
                report5 = (
                    <PowerbiEmbed
                        tbl_name={table}
                        col_name={column}
                        responseURL={props.ProductPerformanceReport}
                        profileId={props.profileId}
                    />
                );
                break;

            default:
                var pageContent = <div>Report not found</div>;
        }
    }

    useEffect(() => {
        setSelectedProducts([]);
    }, [store]);

    const getCampaignPerformanceReport = () => {
        setReport("report1");
    };
    const getWeeklySearchTermReport = () => {
        setReport("report2");
    };
    const getTargetPerformanceReport = () => {
        setReport("report3");
    };
    const getWeeklySalesReport = () => {
        setReport("report4");
    };
    const getProductPerformanceReport = () => {
        setReport("report5");
    };

    const reportPage = () => (
        <>
        <Col md={12}>
        <Card className='card-of-problemarea p-3'>
            <Col md={12} className='d-flex justify-content-between'>
            {props.UserPermission.Reports_WeeklyReports &&<h6 className={`${report==='report4'?'selected-report':'not-selected-report'}`} onClick={getWeeklySalesReport}>
                        <b>Weekly Sales</b>
                    </h6>}
                    {props.UserPermission.Reports_ProductPerformance &&<h6 className={`${report==='report5'?'selected-report':'not-selected-report'}`} onClick={getProductPerformanceReport}>
                            <b>Product Performance</b>
                        </h6>}
                        {props.UserPermission.Reports_Campaigns && <h6 className={`${report==='report1'?'selected-report':'not-selected-report'}`} onClick={getCampaignPerformanceReport}>
                        <b>Campaigns</b>
                    </h6>}
                    {props.UserPermission.Reports_SearchTerms &&  <h6 className={`${report==='report2'?'selected-report':'not-selected-report'}`} onClick={getWeeklySearchTermReport}>
                        <b>Search Terms</b>
                    </h6>}
                    {props.UserPermission.Reports_Targeting && <h6 className={`${report==='report3'?'selected-report':'not-selected-report'}`} onClick={getTargetPerformanceReport}>
                    <b>Targeting</b>
                </h6>}
            </Col>
        </Card>
        </Col>
 
   
          <Fragment>
            <Col md="12" className="pr-0 pl-3 mt-4">
                <Col md="12" className="p-0">
                    {report4?<Label className="w-100">
                        <span> {report4} </span>
                    </Label>:''}
                    {report5?<Label className="w-100">
                        <span> {report5} </span>
                    </Label>:''}
                    {report1?<Label className="w-100">
                        <span> {report1} </span>
                    </Label>:''}
                    {report2?<Label className="w-100">
                        <span> {report2} </span>
                    </Label>:''}
                    {report3?<Label className="w-100">
                        <span> {report3}  </span>
                    </Label>:''}
                </Col>
            </Col>
            <CustomModal
                header={reportName}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={
                    <>
                        <Button color={"link"} onClick={() => setIsOpen(!isOpen)}>
                            Cancel
                        </Button>
                    </>
                }
            ></CustomModal>
        </Fragment>
        </>
      
    );

    return (
        <Fragment>
            <div>
                
                    <Row>{reportPage()}</Row>
                
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.loggedUser.token,
    adProfiles: state.accountOverview.landing.adProfiles,
    accessLevel: state.loggedUser.accessLevel,
    // PowerBI reports
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
    StoreOverviewReport: state.accountOverview.store.StoreOverviewReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    WeeklySearchTermReport: state.accountOverview.store.WeeklySearchTermReport,
    UserPermission: state.accountOverview.store.StoreUserPermission,
    CampaignPerformanceReport: state.accountOverview.store.CampaignPerformanceReport,
});

export default connect(mapStateToProps)(StoreOverviewReports);
