import actionType from '../../Actions/ActionTypes';

const initialState = {
	integerID: '',
	weeklyReport: '',
	ProductPerformanceReport:'',
	TargetPerformanceReport:'',
	StoreOverviewReport:'',
	StoreOverviewPrimeReport:'',
	CampaignPerformanceReport:'',
	StoreUserPermission:'',
	WeeklySalesReport:'',
	WeeklySearchTermReport:'',
	SalesTrendsReport:'',

	storeOverview: {},
	sellerFeedback: {},
	storeProducts: [],
	childProducts: [],
	parentProducts: [],
	orphanProducts: [],
	storeCampaigns: [],
	unclaimedCampaigns: [],
	metricData: {
		clicks: [],
		conversions: [],
		impressions: [],
		sales: [],
		spend: [],
		unitsSold: [],
	},
	campaignUpdates: [],
	creditPurchases: [],
	unitsData: {},
	salesData: {},
	advertisingData: {},
	loading: false,
	status: '',
};

function StoreReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.LOAD_STORE_DATA_BEGIN:
			return {
				...initialState,
				loading: true,
				status: 'loading',
			};
		case actionType.LOAD_STORE_DATA_SUCCESS:
			return {
				...state,
				integerID: action.integerID,
				storeOverview: action.storeOverview,
				weeklyReport: action.weeklyReport,
				ProductPerformanceReport:action.ProductPerformanceReport,
				TargetPerformanceReport:action.TargetPerformanceReport,
				StoreOverviewReport:action.StoreOverviewReport,
				StoreOverviewPrimeReport: action.StoreOverviewPrimeReport,
				CampaignPerformanceReport:action.CampaignPerformanceReport,
				StoreUserPermission:action.StoreUserPermission,
				WeeklySalesReport:action.WeeklySalesReport,
				SalesTrendsReport:action.SalesTrendsReport,
				WeeklySearchTermReport:action.WeeklySearchTermReport,
				sellerFeedback: action.sellerFeedback,
				storeProducts: action.storeProducts,
				childProducts: action.childProducts,
				parentProducts: action.parentProducts,
				orphanProducts: action.orphanProducts,
				storeCampaigns: action.storeCampaigns,
				unclaimedCampaigns: action.unclaimedCampaigns,
				metricData: action.metricData,
				campaignUpdates: action.campaignUpdates,
				creditPurchases: action.creditPurchases,
				loading: false,
				status: 'success',
			};
		case actionType.LOAD_STORE_DATA_FAILED:
			return {
				...initialState,
				loading: false,
				status: 'failed',
			};
		case actionType.LOAD_STORE_UNITS_DATA_BEGIN:
			return {
				...state,
				// loading: true,
				// status: 'loading',
			};
		case actionType.LOAD_STORE_UNITS_DATA_SUCCESS:
			return {
				...state,
				unitsData: action.unitsData,
				// loading: false,
				// status: 'success',
			}
		case actionType.LOAD_STORE_UNITS_DATA_FAILED:
			return {
				...state,
				// loading: false,
				// status: 'failed',
			};
		case actionType.LOAD_STORE_SALES_DATA_BEGIN:
			return {
				...state,
				// loading: true,
				// status: 'loading',
			};
		case actionType.LOAD_STORE_SALES_DATA_SUCCESS:
			return {
				...state,
				salesData: action.salesData,
				// loading: false,
				// status: 'success',
			}
		case actionType.LOAD_STORE_SALES_DATA_FAILED:
			return {
				...state,
				// loading: false,
				// status: 'failed',
			};
		case actionType.LOAD_STORE_AD_DATA_BEGIN:
			return {
				...state,
			};
		case actionType.LOAD_STORE_AD_DATA_SUCCESS:
			return {
				...state,
				advertisingData: action.advertisingData,
			};
		case actionType.LOAD_STORE_AD_DATA_FAILED:
			return {
				...state,
			};
		default:
			break;
	}

	return state;
}

export default StoreReducer;
