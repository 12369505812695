import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button, Container } from 'reactstrap'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { connect } from "react-redux";
import ReactQuill from 'react-quill';

import { faEye, faDownload,faPaperclip, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from 'react-table';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';
import AddHistoryService from '../../../../../../Service/OPSServices/SWInternalServices/AddHistoryService';
import CustomModal from '../../../../../Components/Modals/CustomModal';
import { Editor,SetInnerHtmlWithClassReplacement } from '../../../../../../Utils/Constants';
import MyTasksCompleted from '../../../../../../../src/designUtils/OpsDashboard/MyTasks/MyTasksCompleted.svg';
import MyTasksInProgress from '../../../../../../../src/designUtils/OpsDashboard/MyTasks/MyTasksInProgress.svg';
import MyTasksNew from '../../../../../../../src/designUtils/OpsDashboard/MyTasks/MyTasksNew.svg';
import MyTasksOverdue from '../../../../../../../src/designUtils/OpsDashboard/MyTasks/MyTasksOverdue.svg';
import GetTaskHistoryService from '../../../../../../Service/OPSServices/SWInternalServices/GetTaskHistoryService';


function ViewTaskHistory(props) {
  const [searchInput, setSearchInput] = useState("");
  const [text, setText] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [historyList, setHistoryList] = useState([]);
  const [taskStatus, setTaskStatus] = useState("");
  const [member, setMember] = useState("");
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [isGridCommentsOpen, setIsGridCommentsOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [saveLoading, setSaveLoading] = useState(false)
  const [completing, setCompleting] = useState(false)
  const [commentsText, setCommentsText] = useState()
  const [popupHistory, setPopupHistory] = useState('')
  const [popupTaskTitle,setPupupTaskTitle]=useState('')
  const [taskPriority,setTaskPriority]=useState(props.viewTaskData.priority?props.viewTaskData.priority:'')
  const [loadScreen, setLoadScreen] = useState(false)
  useEffect(() => {
    setLoadScreen(true)
    console.log(props.viewTaskData);
    const data = {
      taskId: props.viewTaskData.SWTeamTaskId
    }
    GetTaskHistoryService(props.token, data).then((responce) => {
      if (responce) {
        console.log('responce')
        console.log(responce)
        setHistoryList(responce.historyList)
        // setTaskPriority(responce.historyList[0].priority)
        console.log(responce.historyList[0].priority);
        
        setLoadScreen(false)
      }
    })

  }, [])

  const handleChangeStatus = (e) => {
    setTaskStatus(e.target.value)
  }
  const handleAssignedToChange = (e) => {
    setMember(e.target.value)
  }
  const handleQuillChange = (value) => {
    setText(value);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleSubmitHistory = (flag) => {

    const formData = new FormData();

    formData.append("taskId", props.viewTaskData.SWTeamTaskId);
    formData.append("status", taskStatus);
    formData.append("taskPriority", taskPriority?taskPriority:props.viewTaskData.priority);
    if(flag !==3){
    formData.append("note", text);
    }
    formData.append("flag", 0);
    if (flag == 1) {
      formData.append("action", 1);
      setCompleting(true)
    }
    if (flag == 2) {
      formData.append("action", 2);
      setSaveLoading(true)
    }
    if(flag ==3){
      formData.append("action", 3);
      formData.append("note", 'Task has been Accepted by '+props.userData.firstName +' '+props.userData.lastName);
      setSaveLoading(true)
    }
    formData.append("assignedTo", member ? member : props.viewTaskData.assignedTo ? props.viewTaskData.assignedTo : '');
    if (selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("taskAttachmentFile", selectedFiles[i]);
      }
    }
    else {
      formData.append("taskAttachmentFile", []);
    }
    console.log(formData);
    AddHistoryService(props.token, formData).then((res) => {
      if (res) {
        console.log(res)
        setSelectedFiles([])
        setTaskStatus('')
        setText('')
        setMember('')
        // setHistoryList(res.historyList)
        props.handleCloseCreateTaskPopup(res.tasklist)
      }
      setCompleting(false)
      setSaveLoading(false)
    })

  }
  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];

  const viewDownloadFile = async (file) => {
    console.log(file)
    try {
      // Extract file name without extension
      let fileName = file.taskAttachmentFile.split('/').pop(); // Get the last part of the path
      fileName = fileName.split('.')[0]; // Remove the file extension
  
      // Fetch the file from the URL
      const fileUrl = file.taskAttachmentFile;
      // const response = await fetch(fileUrl);
      const response = await fetch(fileUrl, {
        method: 'GET',
        //mode: 'no-cors', 
        referrerPolicy: 'no-referrer', // Set referrerPolicy here
        cache: 'no-store',
             });
      
  
      if (!response.ok) {
        throw new Error(`Network response was not ok for file ID: ${file.SWTeamTaskAttachmentId}`);
      }
  
      // Create a blob from the response
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      // Create a temporary download link
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = fileName || 'downloaded_file'; // Use extracted file name or fallback
      document.body.appendChild(downloadLink);
  
      // Trigger the download
      downloadLink.click();
  
      // Cleanup
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
  
      console.log(`File downloaded successfully: ${fileName}`);
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false)
  const [taskExpanded, setTaskExpanded] = useState(false)
  const [taskTitlePopup,setTaskTitlePopup]= useState('')

  const historyNote = SetInnerHtmlWithClassReplacement(props.viewTaskData.taskTitle)
  const TaskReadMore = ({ htmlContent, maxLength, history }) => {

    const toggleReadMore = () => {
      setTaskTitlePopup(history)
      setTaskExpanded(!taskExpanded);
    };
    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div>
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{ fontSize: '13px' }} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            ....Read More
          </span>
        )}
      </div>
    )
  };
  const ReadMore = ({ htmlContent, maxLength, history }) => {

    const toggleReadMore = () => {
      setPopupHistory(history)
      setIsExpanded(!isExpanded);
    };
    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div>
        <div className="issue-description-set d-flex flex-wrap" dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span style={{ fontSize: '13px' }} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            ....Read More
          </span>
        )}
      </div>
    )
  };

  const show = (path) => {
    // Extract file extension from the file path
    console.log(path)
    const separatedPath = path.split("/");
    const fileName = separatedPath.pop(); // Get the file name with extension
    const fileExtension = fileName.split(".").pop(); // Get the extension

    // Check for file extensions that should be closed quickly after showing the modal
    const quickCloseExtensions = ["xlsx", "csv", "xls", "docx"];

    // If the file is of a type that requires quick close (like .xlsx, .csv, etc.)
    if (quickCloseExtensions.includes(fileExtension)) {
      setFilePath(path);        // Set the file path for displaying in the iframe
      setIsAttachmentOpen(true); // Open the modal

      // Close the modal after 200 milliseconds
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      // For all other file types (e.g., PDFs, images), just show the modal without auto-closing
      setFilePath(path);        // Set the file path for the iframe
      setIsAttachmentOpen(true); // Open the modal
    }
  };
  const replaceCommentsText = SetInnerHtmlWithClassReplacement(commentsText);
 
console.log(historyList.assignedToName );
console.log( props.loggedUserId);
const handlePriorityChange=(e)=>{
  setTaskPriority(e.target.value)
  console.log(e.target.value);
}
  return (
    <Row>
      <Col md={6}>
        <Card className='card-of-problemarea  p-3'>
          <Row>
            <Col md={6}>
              <Label><b>Task Title : </b>
              <TaskReadMore htmlContent={historyNote} maxLength={90} history={props.viewTaskData.taskTitle } />
        </Label>
            </Col>
            <Col md={6}>
            <Label className=' d-flex justify-content-end ' >
              <span  style={{backgroundColor:props.viewTaskData.rowColor}} className='viewtaskstatus'>
              {props.viewTaskData.status ==="New"?
              <img src={MyTasksNew} alt='not found' />:
              props.viewTaskData.status ==="In Progress"?
              <img src={MyTasksInProgress} alt='not found' />:
              props.viewTaskData.status ==="Completed"?
              <img src={MyTasksCompleted} alt='not found' />:
              props.viewTaskData.status ==="Overdue"?
              <img src={MyTasksOverdue} alt='not found' />:'' }

              <b>{props.viewTaskData.status}</b>
              </span>
              </Label>
              
            </Col>

            <Col md={6} className='mt-1 mb-1'>
              <Label><b>Planned Start Date : </b>{props.viewTaskData.plannedStartDate}</Label>
            </Col>
            <Col md={6} className='mt-1 mb-1'>
              <Label><b>Planned End Date : </b>{props.viewTaskData.plannedEndDate}</Label>
            </Col>

            <Col md={6}>
            <Label className="d-flex"><b>Assigned To : </b>
              <p className="ml-5 mb-0">
                    {props.viewTaskData.assignedTo == props.loggedUserId && props.viewTaskData.status =="New" ?
                      <>
                      <Input 
                      type='checkbox'
                      // checked={accept}
                      // className='mt-2'
                      onChange={()=>handleSubmitHistory(3)}
                      />
                      Accept </>:''
                    }
                 
                </p></Label>
                {props.viewTaskData.status!=="Completed"?
                <>
                <select
                  id='assigned To'
                  onChange={(e) => handleAssignedToChange(e)
                  }
                  className='form-control w-100 mt-2'>
                  <option id='' disabled selected value=''>--Assigned To--</option>
                  {props.teamMemberList && props.teamMemberList.map((member) => (
                    <option
                      value={member.employeeId}
                      selected={member.employeeId == props.viewTaskData.assignedTo}
                    >{member.employeeName}</option>

                  ))}
                </select> </> :props.viewTaskData.assignedName
            }

            </Col>
            <Col md={6} className='' >
            <Label className=""><b>Priority : </b> </Label>
            {props.viewTaskData.status!=="Completed"?
                <select className='form-control w-100'
                   id="priority"
                  name="priority"
                  value={taskPriority?taskPriority:props.viewTaskData.priority}
                  onChange={handlePriorityChange}>
                  <option id='' disabled selected value=''>--Select Priority--</option>
                  <option value="Critical">Critical</option>
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Normal">Normal</option>
                  <option value="Low">Low</option>
                 
                </select>:
                <p className='mb-0'> {taskPriority}</p>}
                {props.viewTaskData.status ==="Completed"?
                <Label className='mt-2'><b>Completion Date : </b>{props.viewTaskData.completedAt}</Label>:''}
            </Col>
          
            {props.viewTaskData.status!=="Completed"?
            <><Col className='mt-2' md={6}>

            <p for="Attachment">
              <b>Upload Helpful Files : </b>
            </p>
            <label
              htmlFor="fileInput"
              className="custom-file-input-label text-center"
            >
              <i className="fas fa-upload"></i>
              <br /> Choose File

              <input
                type="file"
                id="fileInput"
                name="ticketAttachment"
                className="custom-file-input"
                onChange={handleFileChange}
              />
            </label>
          </Col>
          <Col md={6}>
            <span style={{ fontSize: "13px" }}> <br /><br /> <b>Attached File List :</b></span>
            <ul style={{ color: "green", fontSize: "13px" }}>
              {selectedFiles
                ? selectedFiles.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <button
                      className="btn"
                      onClick={() => handleRemoveFile(file)}
                    >
                      <FontAwesomeIcon
                        color="red"
                        size="15px"
                        icon={faWindowClose}
                      />
                    </button>
                  </li>
                ))
                : ""}
            </ul>

          </Col>

          <Col md={12} className='mt-2'>
            {/* <div className='mendetory-star'>
          <span style={{ color: 'red' }}>
            <b> * </b>
          </span>
        </div> */}
            <Label><b>Comments <span style={{ color: 'red' }}> *</span></b></Label>
            {/* <div className='star-quell'> */}
            <ReactQuill
              className=""
              theme={'snow'}
              modules={Editor.modules}
              formats={Editor.formats}
              value={text}
              onChange={handleQuillChange}
              bounds={'.quill-prod'}
              placeholder='Note' />
            {/* </div> */}
          </Col>
          <Col md={12} className='mt-2 d-flex justify-content-center'>
          <Button className='btn-background-color '
              disabled={!(text) || (saveLoading || completing)}
              onClick={() => handleSubmitHistory(2)}> {saveLoading ? 'Saving..' : 'Add'} </Button>
            <Button className='btn-background-color ml-3'
              disabled={!(text) || (completing || saveLoading)}
              onClick={() => handleSubmitHistory(1)}> {completing ? 'Saving..' : 'Complete'} </Button>
          </Col></>:''

            }
            
          </Row>
        </Card>
      </Col>

      <Col md={6}>
        <LoadingOverlay
          active={loadScreen}
          styles={{

            overlay: (base) => ({
              ...base,
              background: '#f1f4f6',
              opacity: 1,
              marginTop: '15px'
            }),
            content: (base) => ({
              ...base,
              color: '#000',
            }),
          }}
          spinner={<Loader active type="ball-pulse" />}
          text="Loading task history Comments..."
        >
           <Card className='card-of-problemarea p-2 mb-1 scrollable-div p-0 overflow-auto 'style={{ maxHeight: '618px' }}>
          {historyList && historyList.map((history) => {
            console.log(history)
            const historyNote = SetInnerHtmlWithClassReplacement(history.note)
            return (
                <Col md={12} >
                  <Row className='  d-flex justify-content-between'>
                    <Col md={6} className='task-short-name ml-2'><h6><b>{history.createdByName.split(' ').map(word => word[0]).join('').toUpperCase()}</b></h6></Col>
                    <Col md={6}><p className='created-at mt-1'>{history.createdAt}</p></Col>
                    <Col md={12} className='mt-2'>
                      <ReadMore htmlContent={historyNote} maxLength={200} history={history.note} />
                    </Col>
                    {
                      history.files && history.files.length >0?
                      <Col md={12}>
                        {
                          history.files.map((file)=>(
                            <span>
                              {console.log(history.files)}
                              {console.log(file)}
                              {file.taskAttachmentFile.match(/(jpeg|jpg|png|gif)$/i)?
                              <img width={'20%'} onClick={()=>show(file.taskAttachmentFile)} src={file.taskAttachmentFile} className="cursor-pointer"/>:
                              <FontAwesomeIcon
                                className="mr-2 ml-3"
                                icon={faPaperclip}
                                onClick={() => show(file.taskAttachmentFile)}
                              />
                            }
                            </span>
                          ))
                        }
                    
                      </Col>:''
                    }
                  
                  </Row>
                  <p className='linetaskhistory'/>
                </Col>
               
            )
          })}
            </Card>

        </LoadingOverlay>
      </Col>


      <CustomModal
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => viewDownloadFile({ 'taskAttachmentFile': filePath, 'SWTeamTaskAttachmentId': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={'lg'}
        className='my-task-custom-module'
      >
      <Card  className='card-of-problemarea p-2 '>
          <div>
            <iframe
              src={

                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
      </Card>
      </CustomModal>
      <CustomModal
        header={"Task Title"}
        isOpen={taskExpanded}
        toggle={() => setTaskExpanded(!taskExpanded)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setTaskExpanded(!taskExpanded)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="task-title-set" style={{ fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: taskTitlePopup }} />
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>
      <CustomModal
        header={'Comments'}
        isOpen={isGridCommentsOpen}
        toggle={() => setIsGridCommentsOpen(!isGridCommentsOpen)}
        className='my-task-custom-module'
        size={'md'}
      >
        <Card className='card-of-problemarea'>
          <Col md={12} className='p-3'>
            <Label>
              <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceCommentsText }} />
            </Label>
          </Col>
        </Card>

      </CustomModal>
      <CustomModal
        header={"Task comment"}
        isOpen={isExpanded}
        toggle={() => setIsExpanded(!isExpanded)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpanded(!isExpanded)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p className="issue-discription-set" style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: popupHistory }} />
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(ViewTaskHistory)