import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import DashbordTicketListService from '../../../../Service/DashboardServices/DashboardTicketServices/DashbordTicketListService';
import ReactTable from 'react-table';
import { Button, Card, CardBody, CardTitle, Col, Container, Label, Row } from 'reactstrap';
import CustomModal from '../../../Components/Modals/CustomModal';
import ViewTicket from '../TroubleTickets/Components/ViewTicket';
import DashbordViewTicketPage from './Components/DashbordViewTicketPage_v1';
import { SetInnerHtmlWithClassReplacement } from '../../../../Utils/Constants';
import { Chart, DoughnutController, ArcElement, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Cell } from 'jspdf-autotable';
import GetPartnerReportStatisticsService from '../../../../Service/DashboardServices/DashboardTicketServices/GetPartnerReportStatisticsService';
import { height } from '@fortawesome/free-solid-svg-icons/faWindowClose';
// import GetAllPermissionByStoreUserService from '../../../../Service/DashboardServices/AccountOverviewServices/GetAllPermissionByStoreUserService';
import DashbordViewTicketPage_v1 from './Components/DashbordViewTicketPage_v1';
import FetchAllPermissionByStoreUserService from '../../../../Service/DashboardServices/AccountOverviewServices/FetchAllPermissionByStoreUserService';
Chart.register(DoughnutController, ChartDataLabels, ArcElement, Legend, Tooltip);

function DashbordTicketListPage_v1(props) {
  const [statusFilter, setStatusFilter] = useState("Open");
  const [query, setQuery] = useState("");
  const [ticketList, setTicketList] = useState([]);
  const [mainRequestTypeTitle, setMainRequestTypeTitle] = useState("");
  const [pathwayTitle, setPathwayTitle] = useState("");
  const [subTypeTitle, setSubTypeTitle] = useState("");
  const [ticketId, setTicketId] = useState();
  const [isViewTicketOpen, setIsViewTicketOpen] = useState(false);
  const [createDate, setCreateDate] = useState("");

  //view ticket
  const [mainRequestTypes, setMainRequestTypes] = useState();
  const [ticketStatuslist, setTicketStatuslist] = useState();
  const [subRequestTypes, setSubRequestTypes] = useState();
  const [ticketViewData, setTicketViewData] = useState();
  const [problemAreaPathwayList, setProblemAreaPathwayList] = useState();
  const [isCreateTicketOpen, setIsCreateTicketOpen] = useState(false);
  const [problemAreaList, setProblemAreaList] = useState();
  const [issueDescriptionText, setIssueDescriptionText] = useState("");
  const [issueDescription, setIssueDescription] = useState(false);


  const doughnutChartRef1 = useRef(null);
  const doughnutChartRef = useRef(null);
  const pieChartInstance = useRef(null);
  const doughnutChartInstance = useRef(null);
  const [overAllStatistics, setOverAllStatistics] = useState()
  const [allStatusCount, setAllStatusCount] = useState()
  const [statusName, setStatusName] = useState([])
  const [statusCount, setStatusCount] = useState([])
  const [statusColor, setStatusColor] = useState([])
  const [pieLabels, setPieLabels] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [pieColors, setPieColors] = useState([]);
  const [permissions, setpermissions] = useState();
  const [ticketHistoryComments, setTicketHistoryComments] = useState('');

  useEffect(() => {
    FetchAllPermissionByStoreUserService(props.token).then((res)=>{
      if(res)
      {
        console.log(res);
        setpermissions(res)
      }
    })
  }, []);
  useEffect(() => {
    GetPartnerReportStatisticsService(props.token).then((res) => {
      if (res) {
        console.log(res);
        setOverAllStatistics(res.overAllStatistics)
        const statusNames = res.statusCount.map(item => item.status);
        const statusCounts = res.statusCount.map(item => item.count);
        const statusColors = res.statusCount.map(item => item.color);
        setStatusCount(statusCounts)
        setStatusName(statusNames)
        setStatusColor(statusColors)

        const openCount = res.statusCount
          .filter(item => !["Closed", "Resolved"].includes(item.status))
          .reduce((acc, item) => acc + item.count, 0);

        const pieChartData = [
          { status: "Open", count: openCount, color: "#be0000" },
          ...res.statusCount.filter(item => ["Closed", "Resolved"].includes(item.status))
        ];
        setPieLabels(pieChartData.map(item => item.status));
        setPieData(pieChartData.map(item => item.count));
        setPieColors(pieChartData.map(item => item.color));
        console.log(pieChartData);
      }
    })
  }, []);
  useEffect(() => {
    if (statusName.length && statusCount.length && statusColor.length && pieLabels.length && pieData.length && pieColors.length) {
      const pieChartContext = doughnutChartRef1.current.getContext('2d');
      const doughnutChartContext = doughnutChartRef.current.getContext('2d');

      // Destroy existing chart instances if they exist
      if (pieChartInstance.current) pieChartInstance.current.destroy();
      if (doughnutChartInstance.current) doughnutChartInstance.current.destroy();

      // Create Pie Chart
      pieChartInstance.current = new Chart(pieChartContext, {
        type: 'doughnut',
        data: {
          labels: pieLabels,
          datasets: [
            {
              data: pieData,
              backgroundColor: pieColors,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: 'white', // color of the label text
              font: {
                size: 16,
                weight: 'bold',
              },
              formatter: (value) => (value > 0 ? value : ''), // display the value directly
              // formatter: (value, context) => {
              //   const label = context.chart.data.labels[context.dataIndex];
              //   return value > 0 ? `${label}: ${value}` : ''; // display "Status: Count" if count > 0
              // },
            },
          },
          title: {
            display: true,
            fontSize: 19,
            color: 'black',
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      doughnutChartInstance.current = new Chart(doughnutChartContext, {
        type: 'doughnut',
        data: {
          labels: statusName,
          datasets: [
            {
              data: statusCount,
              backgroundColor: statusColor,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: 'white', // color of the label text
              font: {
                size: 16,
                weight: 'bold',
              },
              formatter: (value) => (value > 0 ? value : ''), // display the value directly
              // formatter: (value, context) => {
              //   const label = context.chart.data.labels[context.dataIndex];
              //   return value > 0 ? `${label}: ${value}` : ''; // display "Status: Count" if count > 0
              // },
            },
          },
          title: {
            display: true,
            fontSize: 19,
            color: 'black',
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });

    }
  }, [statusName, statusCount, statusColor, pieColors, pieData, pieLabels]);

  const filteredTicketList = ticketList.filter((row) => {
    const issueDescription = (row.issueDescription || "").toLowerCase();
    const requestSubTypes = row.requestSubTypes.toLowerCase();
    const ticketStatus = row.ticketStatus.toLowerCase();
    const problemAreaPathway = row.problemAreaPathway.toLowerCase();
    const storeName = row.storeName.toString().toLowerCase();
    const problemArea = row.problemArea.toLowerCase();
    const priority = row.priority.toLowerCase();

    const ticketId = row.ticketId.toString();
    if (query !== "") {
      return (
        issueDescription.includes(query.toLowerCase()) ||
        requestSubTypes.includes(query.toLowerCase()) ||
        requestSubTypes.includes(query.toLowerCase()) ||
        ticketStatus.includes(query.toLowerCase()) ||
        problemAreaPathway.includes(query.toLowerCase()) ||
        storeName.includes(query.toLowerCase()) ||
        problemArea.includes(query.toLowerCase()) ||
        priority.includes(query.toLowerCase()) ||

        ticketId.includes(query.toLowerCase())
      );
    } else if (statusFilter === "All") {
      return true; // Show all tickets when "All" is selected
    } else if (statusFilter) {
      if (statusFilter == "Open") {
        return row.ticketStatus !== "Closed" && row.ticketStatus !== "Resolved";
      } else {
        return row.ticketStatus === statusFilter;
      }
    } else {
      return true; // Show all tickets when "All" is selected
    }
  });

  const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(issueDescriptionText);

  console.log(props)
  useEffect(() => {
    console.log(props)
    const fetchFilteredTickets = async () => {
      const response = await DashbordTicketListService(props.token);
      console.log(response)
      // setCreateDate(response.ticketList[0].ticketCreatedAt)
      setTicketList(response.ticketList)
      setTicketStatuslist(response.ticketStatuslist);
      setMainRequestTypes(response.mainRequestTypes);
      setSubRequestTypes(response.subRequestTypes);
      // setTicketViewData(response.ticketHistory);
      setTicketStatuslist(response.ticketStatuslist);
      setTicketHistoryComments(response.ticketStatuslist[0].ticketHistoryComment)

    }
    fetchFilteredTickets();
  }, []);
  const [priority, setPriority] = useState("");
  const handleViewTicket = (ticketId, mainType, pathway, subType,createrPriority) => {
    console.log(ticketId);
    setTicketId(ticketId);
    setMainRequestTypeTitle(mainType);
    setPathwayTitle(pathway);
    setSubTypeTitle(subType);
    setIsViewTicketOpen(true)
    setPriority(createrPriority)
  };

  const handleCreateAndViewTicketClose = (response) => {
    setTicketList(response.ticketList);
    setMainRequestTypes(response.mainRequestTypes);
    // setMainCategories(response.mainCategories);
    setSubRequestTypes(response.subRequestTypes);
    // setTicketViewData(response.ticketHistory);
    setTicketStatuslist(response.ticketStatuslist);
    setIsCreateTicketOpen(false);
    setIsViewTicketOpen(false);
  };
  const viewIssueDescription = (text) => {
    setIssueDescription(true);
    setIssueDescriptionText(text);
  };

  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const columns = [
    {
      Header: "ID",
      accessor: "ticketId",
      width: 80,
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Priority",
      accessor: "createrPriority",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Date",
      accessor: "ticketOriginaldate",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.original.ticketCreatedAt}</p>
        </div>
      ),
    },
    {
      Header: "Store Name",
      accessor: "storeName",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: " Problem Area",
      accessor: "problemArea",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Pathway",
      accessor: "problemAreaPathway",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Sub Request ",
      accessor: "requestSubTypes",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Issue Description ",
      accessor: "issueDescription",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p
            className="m-0 comment-paragraph"
            style={{ color: "#3D85C6", cursor: "pointer" }}
            onClick={() =>
              viewIssueDescription(
                cellInfo.value,
                cellInfo.original.issueDescription
              )
            }
          >
            {stripHtmlTags(cellInfo.value)}
          </p>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "ticketStatus",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span
            className={
              cellInfo.value === "Closed"
                ? "text-primary "
                : cellInfo.value === "New"
                  ? "text-danger  "
                  : cellInfo.value === "Informed"
                    ? "text-secondary "
                    : cellInfo.value === "Resolved"
                      ? "text-success  "
                      : cellInfo.value === "Need info"
                        ? "text-info "
                        : cellInfo.value === "Assigned"
                          ? "text-warning"
                          : cellInfo.value === "Ticket updated"
                            ? "text-warning"
                            : "btn btn-sm"
            }
          >
            <a
              className="m-0"
              href="#"
              onClick={() => {
                handleViewTicket(
                  cellInfo.original.ticketId,
                  cellInfo.original.requestType,
                  cellInfo.original.problemAreaPathway,
                  cellInfo.original.requestSubTypes,
                  cellInfo.original.createrPriority
                ); setCreateDate(cellInfo.original.ticketCreatedAt)
              }
              }
            >
              {cellInfo.value}
            </a>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Container style={{ marginTop: '75px' }}>
        <Row>
          {
            overAllStatistics && overAllStatistics.map((stat) => (<><Col xs="12" md="3">
              <Card style={{ borderRadius: '10px', overflow: 'hidden' }}>
                <CardBody style={{ padding: '10px' }}>
                  <CardTitle tag="h5" style={{ textAlign: 'center', backgroundColor: '#B1FA63', display: 'block', padding: '6px' }}>{stat.title}</CardTitle>
                  <p className='pb-0 mb-0' style={{ textAlign: 'center', fontSize: '20px', fontWeight: '500' }}><strong>{stat.count}</strong></p>
                </CardBody>
              </Card>
            </Col></>))
          }
          <Col md={12} className='text-center' style={{ fontSize: '20px', fontWeight: '500', padding: '6px', marginTop: '15px'}}>
            <Row className='justify-content-center'  >
              <Col md="5" className="mt-4">
              {/* <Col md="5" className="mt-4" style={{ height:'220px' }}> */}
                <canvas style={{ width: '100%', height: '100%' }} ref={doughnutChartRef1} />
              </Col>
              <Col md="7" >
              {/* <Col md="7" style={{ height:'245px' }}> */}
                <canvas style={{ width: '100%', height: '100%' }} ref={doughnutChartRef} />
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
      <Container fluid className="p-0" style={{ marginTop: 0 }}>
        <Row className="m-0">
          <Col md="12" className="mbg-3 p-0">
            <Card className="main-card mb-3">
              <CardBody>
                <Row md="12">
                  <Col md="12">
                    <CardTitle>Ticket List </CardTitle>
                  </Col>
                  <Col md={12}>
                    <label>
                      <input
                        type="text"
                        placeholder="Search Text"
                        value={query}
                        className="search form-control "
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </label>
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button className='btn-class'>Create Ticket</Button>
                    </div> */}
                  </Col>

                </Row>
                <span className="mb-2" style={{ marginLeft: "0px" }}>
                  <input
                    type="radio"
                    name="statusFilter"
                    value={statusFilter}
                    checked={statusFilter === "All"}
                    className="ml-2"
                    onChange={() => setStatusFilter("All")}
                  />
                  &nbsp;
                  <b>All</b>
                </span>
                <span className="mb-2" style={{ marginLeft: "40px" }}>
                  <input
                    type="radio"
                    name="statusFilter"
                    value={statusFilter}
                    checked={statusFilter === "Open"}
                    className="ml-2"
                    onChange={() => setStatusFilter("Open")}
                  />
                  &nbsp;
                  <b>Open</b>
                </span>
                {ticketStatuslist
                  ? ticketStatuslist.map((status) => (
                    <span className="mb-2" style={{ marginLeft: "30px" }}>
                      <input
                        type="radio"
                        name="statusFilter"
                        value={status.ticketStatus}
                        checked={statusFilter === status.ticketStatus}
                        className="ml-2"
                        onChange={() => setStatusFilter(status.ticketStatus)}
                      />
                      &nbsp;
                      <b>{status.ticketStatus}</b>
                    </span>
                  ))
                  : ""}

                <ReactTable
                  data={filteredTicketList}
                  columns={columns}
                  className="-fixed -highlight -striped mt-2"
                  getTheadProps={() => {
                    return {
                      style: {
                        overflowY: "hidden",
                        background: "#B1FA63",
                        color: "#243837",
                      },
                    };
                  }}
                  defaultPageSize={10}
                  noDataText={"No Data Found."}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <CustomModal
        header={ticketId + " / " + pathwayTitle + " / " + subTypeTitle + " / " + createDate}
        isOpen={isViewTicketOpen}
        toggle={() => setIsViewTicketOpen(!isViewTicketOpen)}
        size={"xl"}
        className="bgColor"
      >
        <DashbordViewTicketPage_v1
        ticketHistoryComments={ticketHistoryComments}
          ticketId={ticketId}
          ticketList={ticketList}
          ticketStatuslist={ticketStatuslist}
          mainRequestTypes={mainRequestTypes}
          subRequestTypes={subRequestTypes}
          ticketViewData={ticketViewData}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          problemAreaPathwayList={problemAreaPathwayList}
          problemAreaList={problemAreaList}
          pathwayTitle={pathwayTitle}
          priority={priority}
          subTypeTitle={subTypeTitle}
          problemAreaId={props.problemAreaId}
          problemAreaPathwayId={props.problemAreaPathwayId}
        />
      </CustomModal>
      <CustomModal
        header={"Issue Description"}
        isOpen={issueDescription}
        toggle={() => setIssueDescription(!issueDescription)}
        size={"xl"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIssueDescription(!issueDescription)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} />
          {/* <p>{issueDescriptionText}</p> */}
        </Label>
      </CustomModal>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    StoreUserPermission: state.accountOverview.store.StoreUserPermission,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(DashbordTicketListPage_v1);
