import React, { useState,useEffect } from 'react'
import { Card, Col, Container, Row } from 'reactstrap'
import SwTeamCreatetask from './Components/SwTeamCreatetask'
import SWTeamTask from './Components/SWTeamTask'
import MyTasksDropdown from '../../../../../src/designUtils/OpsDashboard/MyTasks/MyTasksDropdown.svg';
import NewTaskIcon from '../../../../../src/designUtils/OpsDashboard/MyTasks/NewTaskIcon.svg';
import MyTasksUpIcon from '../../../../../src/designUtils/OpsDashboard/MyTasks/MyTasksUpIcon.svg';
import MyTicketsUpIcon from '../../../../../src/designUtils/OpsDashboard/MyTasks/MyTicketsUpIcon.svg';
import MyWFUpIcon from '../../../../../src/designUtils/OpsDashboard/MyTasks/MyWFUpIcon.svg';
import MyTicketsDropdown from '../../../../../src/designUtils/OpsDashboard/MyTasks/MyTicketsDropdown.svg';
import MyWFDropdown from '../../../../../src/designUtils/OpsDashboard/MyTasks/MyWFDropdown.svg';
import { connect } from 'react-redux';
import PieChart from './Components/PieChart'
import { height } from '@fortawesome/free-solid-svg-icons/faWindowClose'
import CreateNewTask from './Components/SubComponentsSwTeamTask/CreateNewTask'
import NotiDropdownUp from '../../../../../src/designUtils/OpsDashboard/MyTasks/NotiDropdownUp.svg';
import NotiDropdown from '../../../../../src/designUtils/OpsDashboard/MyTasks/NotiDropdown.svg';



import WorkflowsMainPage from '../Operations/Scenes/Workflows/WorkflowsMainPage'
import OpsDashTicketListPage from '../Operations/Scenes/opsDashTickets/OpsDashTicketListPage';
import CustomModal from '../../../Components/Modals/CustomModal';
import getAllStatistics from '../../../../Service/OPSServices/SWInternalServices/getAllStatistics';
import GetAllTasksServices from '../../../../Service/OPSServices/SWInternalServices/GetAllTasksServices';
import GetUserProfileDataService from '../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/GetUserProfileDataService';
import { faLiraSign } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';
import NotificationsMainFile from '../StoreOverview_v1/Scenes/Components/PartnerUpdateLog/NotificationsMainFile';

function MyTasksMainPage(props) {
  const [tabs, setTabs] = useState(3)
  const [myTaskIcons,setMyTaskIcons]= useState(true)
  const [ticketIcons,setTicketIcons]= useState(false)
  const [workflowsIcons,setWorkflowsIcons]= useState(false)
  const [createNewTaskPopup,setCreateNewTaskPopup]= useState(false)
  const [taskList, setTaskList] = useState([])
  const [teamMemberList, setTeamMemberList] = useState('')
  const [allStats, setallStats] = useState()
  const [cardTitle, setCardTitle] = useState();
  const[notificationIcons,setNotificationIcons]= useState(false)
 

  const [loggedUserId , setloggedUserId] = useState()
  const [taskCount , setTaskCount] = useState(0)
  const [worflowCount,setworflowCount]= useState(0)
  const [ticketCount,setticketCount]= useState(0)
  const [taskStatus, setTaskStatus] = useState()
  const [loading,setloading]= useState(false)

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
  });
  const [statusNames, setstatusName] = useState([])
  const [statusColor, setstatusColor] = useState([])
  const [statusCount, setstatusCount] = useState([])
  const [notiCount, setNotiCount] = useState('0');
  useEffect(() => {
    const fetchData = () => {
    setloading(true)
    getAllStatistics(props.token).then((res) => {
      if (res) {
        console.log(res);
        setNotiCount(res.notiCount);
        console.log(res.taskStatistics);
        console.log(res.taskStatistics.map((item) => item.status));
        setallStats(res)
        setTaskCount(res.taskStatistics[0].count)
        setworflowCount(res.workflowStatistics[0].count)
        setticketCount(res.ticketStatistics[0].count)
        setstatusName(res.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.status))
        setstatusColor(res.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.color))
        setstatusCount(res.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.count))
        console.log(res.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.status));
        setloading(false)
      }
    })
    console.log(statusNames);
    GetAllTasksServices(props.token).then((responce) => {
      if (responce) {
        console.log('responce')
        console.log(responce)
        setloggedUserId(responce.loggedUserId)
        setTaskList(responce.tasklist)
        setTeamMemberList(responce.teamMembers)
        setTaskStatus(responce.statusList)

        // setTaskStatus(responce.statusList)
        // const statusName = responce.statusList.map(item => item.status);
        // setstatusName(statusName)
        // const tasks = responce.tasklist.filter((task) => task.status != 'Completed' && task.assignedTo == responce.loggedUserId);
        // setTaskCount(tasks.length)
      }
    })
    GetUserProfileDataService(props.token).then((response) => {
      if (response) {
        console.log(response);
        setUserData({
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          phone: response.phone,
          companyName: response.companyName,
        })
      }
    })
}
handleTimeDate()
fetchData(); 
  // const intervalId = setInterval(() => {
  //   handleTimeDate();
  //   fetchData();
  // }, 180000); 
  // return () => clearInterval(intervalId);
}, []); 

  const handleChangeTabs = (value) => {
    if (value === 1) {
      setCardTitle("My Workflow Tasks");
      setstatusName(allStats &&allStats.workflowStatistics.filter((item) => item.status !== 'Open').map((item)=>item.status))
      setstatusColor(allStats &&allStats.workflowStatistics.filter((item) => item.status !== 'Open').map((item)=>item.color))
      setstatusCount(allStats &&allStats.workflowStatistics.filter((item) => item.status !== 'Open').map((item)=>item.count))
      setWorkflowsIcons(!workflowsIcons)
      setTicketIcons(false)
      setMyTaskIcons(false)
      setNotificationIcons(false)

    }
    if (value === 2) {
      setCardTitle("My Tickets");
      setstatusName(allStats &&allStats.ticketStatistics.filter((item) => item.status !== 'Open').map((item) => item.status))
      setstatusColor(allStats &&allStats.ticketStatistics.filter((item) => item.status !== 'Open').map((item) => item.color))
      setstatusCount(allStats &&allStats.ticketStatistics.filter((item) => item.status !== 'Open').map((item) => item.count))
      setTicketIcons(!ticketIcons)
      setMyTaskIcons(false)
      setWorkflowsIcons(false)
      setNotificationIcons(false)

    }
    if (value === 3) {
      setCardTitle("My Tasks");
      setstatusName(allStats &&allStats.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.status))
      setstatusColor(allStats &&allStats.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.color))
      setstatusCount(allStats &&allStats.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.count))
      setMyTaskIcons(!myTaskIcons)
      setWorkflowsIcons(false)
      setTicketIcons(false)
      setNotificationIcons(false)
    }
    if (value === 4) {
      setCardTitle("My Notification");
      // setstatusName(allStats &&allStats.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.status))
      // setstatusColor(allStats &&allStats.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.color))
      // setstatusCount(allStats &&allStats.taskStatistics.filter((item) => item.status !== 'Open').map((item) => item.count))
      setNotificationIcons(!notificationIcons)
      setWorkflowsIcons(false)
      setTicketIcons(false)
      setMyTaskIcons(false)


    }
    // setMyTaskIcons(value, !myTaskIcons)
    // setShowTabsUi(!showTabsUi)
    setTabs(value)
  }
  const handleCreateTaskPopup = () => {

    setCreateNewTaskPopup(true)
  }
  const handleCloseCreateTaskPopup = (task) => {
    setTaskCount(taskCount+1)
    setCreateNewTaskPopup(false)
    setTaskList(task)
  }
  const [currentTime, setCurrentTime] = useState(new Date());
  const handleTimeDate = () => {
    setCurrentTime(new Date());

  }

  const formattedDate = currentTime.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  function handleClickViewAnalytic() {
    alert("Development for this functionality is in progress.");
  }

  return (
    <Col className='mt-5 pt-4'>
      <Row>
        <Col md={9}>
          <Row>
            <Col md={12}>
              <h5>
                <b>Hello, {userData.firstName}</b>
              </h5>
              <h6> <b>{`${formattedDate}, ${formattedTime}`}</b></h6>
              <div className='d-flex mt-4 mb-3' >
                <div className='d-flex justify-content-center message-color 'onClick={handleClickViewAnalytic}>
                  <h6 className='font-color-heading  mr-2 mt-2' ><b>Messages</b> </h6>
                  {/* <h6 className='message-count'><b>5</b></h6> */}
                </div>

                <div className='new-task-color' onClick={handleCreateTaskPopup}>
                  <h6 className='font-color-heading  mt-2' ><b>New Task </b> <img className='ml-3 mb-1'  src={NewTaskIcon} /></h6>
                </div>

              </div>
            </Col>
            <Col md={3}>
              <Card
                onClick={() => { handleChangeTabs(3) }}
                className={`p-4 card-of-problemarea swteam-tasks mt-3`}>
                <h6
                  style={{ color: '#243837' }}
                >
                  <b>Tasks</b></h6>
                <div className="d-flex justify-content-between">
                  <h6 style={{ color: '#243837' }}><b>{taskCount}</b></h6>

                  <img
                    src={myTaskIcons ? MyTasksUpIcon :  MyTasksDropdown} />
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <Card
                onClick={() => { handleChangeTabs(4) }}
               className={`p-4 card-of-problemarea notification-tasks mt-3`}>
                <h6
                style={{color: '#243837' }}>
                  <b>Notifications</b>
                </h6>
                <div className="d-flex justify-content-between">
                <h6 style={{ color: '#243837' }}><b>{notiCount}</b></h6>
                <img src={notificationIcons ? NotiDropdownUp:  NotiDropdown } />
                </div>
              </Card>
            </Col>
           
            <Col md={3}>
              <Card
                onClick={() => { handleChangeTabs(2) }}
                className={`p-4 card-of-problemarea ticket-tasks mt-3`} >
                <h6
                  style={{ color: '#243837' }}
                >
                  <b>Tickets</b></h6>
                <div className="d-flex justify-content-between">
                  <h6 style={{ color: '#243837' }}><b>{ticketCount}</b></h6>

                  <img src={ticketIcons ?  MyTicketsUpIcon:  MyTicketsDropdown} alt="Dropdown Icon" />
                </div>
              </Card>
              </Col>
              <Col md={3}>
              <Card className={`p-4 card-of-problemarea workflow-tasks mt-3`}
                onClick={() => { handleChangeTabs(1) }} >
                <h6
                  style={{ color: '#243837' }}
                >
                  <b>Workflows</b></h6>
                <div className="d-flex justify-content-between">
                  <h6 style={{ color: '#243837' }}><b>{worflowCount}</b></h6>
                  <img src={workflowsIcons ? MyWFUpIcon:  MyWFDropdown } />
                </div>
              </Card>
            </Col>
          </Row>

        </Col>
        {tabs!==4 &&
        <Col md={3}>
        <Card className='card-of-problemarea p-2' style={{ height: '275px' }}>
          <div className='custom-task-overview'>
            <h5 className='text-center'><b>{cardTitle?cardTitle:"My Tasks"}</b></h5></div>
          {/* <h6 className='text-center'><b>Nov 2024</b></h6>    */}
          <PieChart Status={statusNames} count={statusCount} color={statusColor} />
        </Card>
      </Col>


        }
        
        <Col md={12}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: '#f1f4f6',
              opacity: 1,
            }),
            content: (base) => ({
              ...base,
              color: '#000',
            }),
          }}
          spinner={<Loader active type="ball-pulse" />}
          text='Loading your Tasks...'
      >
          {
            (tabs === 1) ?
              <WorkflowsMainPage isMyworksScreen={true} /> :
              (tabs === 2) ?
                <OpsDashTicketListPage isMyworksScreen={true} /> :
                (tabs===4)?
                 <NotificationsMainFile isMyworksScreen={true}/>:
                (tabs === 3) ?
                  <SWTeamTask  
                  setstatusCount={setstatusCount}
                  setTaskStatus={setTaskStatus}
                  taskStatus={taskStatus}
                  loggedUserId={loggedUserId}
                  setTaskList={setTaskList}
                  taskList={taskList}
                  userData ={userData}
                  teamMemberList={teamMemberList}
                  setTeamMemberList={setTeamMemberList}/> : ''
            // <SwTeamCreatetask/>:''
          }
          </LoadingOverlay>
        </Col>
      </Row>
      <CustomModal
        header={"Create New Task"}
        isOpen={createNewTaskPopup}
        toggle={() => setCreateNewTaskPopup(!createNewTaskPopup)}
        size={"lg"}
        className='my-task-custom-module'
      >
          <Card className='card-of-problemarea'>
          <CreateNewTask
           loggedUserId={loggedUserId}
            teamMemberList={teamMemberList}
            taskList={taskList}
            setTaskCount={setTaskCount}
            taskCount={taskCount}
            setstatusCount={setstatusCount}
            handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
          />
        </Card>
      </CustomModal>
    </Col>
  )
}

const mapStateToProps = state => {
  return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
  }
}

export default connect(mapStateToProps)(MyTasksMainPage);
